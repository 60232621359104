import { createContext, useEffect, useState, useMemo, useCallback } from "react";
import randomColor from "randomcolor";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2/src/sweetalert2";
import ReactDOMServer from "react-dom/server";
import withReactContent from "sweetalert2-react-content";
import { changeDpiDataUrl } from "changedpi";
import moment from "moment";

export const CadContext = createContext(null);

export const CadProvider = (props) => {
  const [patternDATA, setpatternDATA] = useState();
  const [weaveDATA, setweaveDATA] = useState({
    //2/2RHT
    pegplan: [
      [0, 0, 1, 1],
      [0, 1, 1, 0],
      [1, 1, 0, 0],
      [1, 0, 0, 1],
    ],
    dent: [1, 2, 3, 4],
    dentrepeat: [0, 0, 0, 1],
    name: "2/2RHT",
  });

  const [dobbyDATA, setdobbyDATA] = useState();
  const [refreshAction, setrefreshAction] = useState();

  const scalex = 1;
  const scaley = 1;

  const epi = 61;
  const [pattern_weave, setpattern_weave] = useState();
  const [pattern_warp, setpattern_warp] = useState();
  const [pattern_weft, setpattern_weft] = useState();
  const [charDATA, setcharDATA] = useState();
  const [cadconfig, setcadconfig] = useState({ reed: 60, pick: 60, unit: "end" });
  const [selected_colorwayIndex, setselected_colorwayIndex] = useState(0);
  const [colorwaysDATA, setcolorwaysDATA] = useState([]);
  const tempColors = useMemo(() => randomColor({ count: 25, hue: "monochrome", luminosity: "random" }), []);

  const [erpDATA, seterpDATA] = useState();
  const [zoom, setzoom] = useState();
  const [appversion, setappversion] = useState();

  const [extrafilters, setextrafilters] = useState();

  const get_patternAlphabets = () => {
    var arr = [];
    var warp = [];
    var weft = [];
    if (Array.isArray(patternDATA)) {
      patternDATA.forEach((d) => {
        if (d) {
          d.forEach((s, i) => {
            if (s) {
              if (/^([A-z][0-9.]+)$/.test(s) || !s) {
                let char = s.charAt(0);
                if (char && char !== "X") {
                  if (arr.indexOf(char) === -1) arr.push(char);

                  if (i === 0) {
                    if (warp.indexOf(char) === -1) warp.push(char);
                  } else {
                    if (weft.indexOf(char) === -1) weft.push(char);
                  }
                }
              } else {
                console.log(false);
              }
            }
          });
        }
      });
    }
    arr = arr.sort((a, b) => ("" + a.attr).localeCompare(b.attr));
    warp = warp.sort((a, b) => ("" + a.attr).localeCompare(b.attr));
    weft = weft.sort((a, b) => ("" + a.attr).localeCompare(b.attr));
    return { arr, warp, weft };
  };

  function get_grindleFilter(color2) {
    if (!color2) return null;
    let c = hexToColorizedColorMatrix(color2);
    if (!c) return null;
    return (
      <filter id={`grindleFilter_${color2}`} x={0} y={0} width={"100%"} height={"100%"} filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="linearRGB">
        <feTurbulence type="fractalNoise" result="myTurbulence" baseFrequency="0.5 0.20" numOctaves="5" seed="2" stitchTiles="stitch" x="0%" y="0%" width="100%" height="100%" />
        <feComposite operator="in" in="myTurbulence" in2="SourceAlpha" result="myComposite" />
        <feColorMatrix in="myComposite" type="matrix" values={c} result="colormatrix" />
        <feComposite operator={"over"} in2="SourceGraphic" in="colormatrix" />
      </filter>
    );
  }

  useEffect(() => {
    if (weaveDATA) {
      var data;
      if (typeof weaveDATA === "object" && weaveDATA.pegplan && weaveDATA.dent && weaveDATA.dentrepeat) {
        //Expanding with Dent & Dent Repeat
        data = expandDentAction(weaveDATA.dentrepeat, weaveDATA.pegplan, weaveDATA.dent, weaveDATA.pegrepeat);
      } else alert("Error in Weave DATA");

      if (Array.isArray(data)) {
        let points = [];
        for (let y = 0; y < data.length; y++) {
          for (let x = 0; x < data[y].length; x++) {
            if (data[y][x] === 1) {
              points.push(<rect key={`weave_rect${y}${x}`} x={x * scalex} y={y * scaley} width={scalex} height={scaley} />);
            }
          }
        }
        let s1 = epi / cadconfig.reed;
        let s2 = epi / cadconfig.pick;
        let p = (
          <pattern id="weave_pattern" width={data[0].length * scalex} height={data.length * scaley} patternUnits="userSpaceOnUse" patternTransform={`scale(${s1} ${s2})`}>
            {points}
          </pattern>
        );

        setpattern_weave(p);
      }
    }
  }, [weaveDATA, cadconfig.reed, cadconfig.pick]);

  useEffect(() => {
    if (props.onOpen && typeof props.onOpen !== "boolean") {
      openFile(props.onOpen);
    } else {
      //Set Default Pattern DATA
      let data = [
        ["A10", "A10"],
        ["B10", "B10"],
      ];
      for (var i = 0; i < 200; i++) {
        data.push([null, null]);
      }
      setpatternDATA(data);

      let data2 = [
        {
          DATA: [
            { name: "Black-10", char: "A", color: "#000000" },
            { name: "Grey-1", char: "B", color: "#a9a9a9" },
          ],
          headDATA: { title: `Colorway 1`, id: uuidv4() },
        },
      ];
      setcolorwaysDATA(data2);
    }
  }, [props.onOpen]);

  useEffect(() => {
    patternAction(true);
  }, [patternDATA, selected_colorwayIndex, colorwaysDATA]);

  const patternAction = (draw, index) => {
    if (patternDATA && patternDATA.length > 0 && patternDATA[0] && (patternDATA[0][0] || patternDATA[0][1])) {
      let chars = get_patternAlphabets();
      setcharDATA(chars);

      let x = 0,
        y = 0,
        real_x = 0,
        real_y = 0;

      let warp_x = 0,
        weft_x = 0;
      let warp_colors_ends = {};

      let _extrafilters = [];
      let _extrafilters_keys = [];

      const drawPattern = (d, iswarp, i, single) => {
        let char = d.charAt(0);
        var v = parseFloat(d.substring(1));
        let real_v = convertunit(parseFloat(d.substring(1)), cadconfig.unit, null, iswarp ? cadconfig.reed : cadconfig.pick);
        if (char === "X") {
          var r = [];
          if (v > 1) {
            for (var l = 0; l < v - 1; l++) {
              for (var k = iswarp ? warp_x : weft_x; k < i; k++) {
                let t = iswarp ? patternDATA[k][0] : patternDATA[k][1];
                if (t) {
                  let p = drawPattern(t, iswarp, k);
                  r.push(p.r);
                }
              }
            }
          }
          iswarp ? (warp_x = i + 1) : (weft_x = i + 1);
          return { r, data: { d, v, real_v } };
        } else {
          function execute1(e, _e) {
            if (cadconfig.unit === "in") v = Math.round(v * e);
            else if (cadconfig.unit === "cm") v = Math.round(v * (e / 2.54));
            else if (cadconfig.unit === "mm") v = Math.round((v * (e / 2.54)) / 10);
            //v = (v / e) * _e;
          }

          if (iswarp) {
            execute1(cadconfig.reed, null); //c_reed
          } else {
            execute1(cadconfig.pick, null); //c_pick
          }

          let char_index = chars.arr.indexOf(char);
          if (char_index > -1) {
            const c = getColorAtIndex(char_index, char, index ?? selected_colorwayIndex);

            if (c.color2) {
              if (_extrafilters_keys.indexOf(c.color2) === -1) {
                _extrafilters_keys.push(c.color2);
                let f = get_grindleFilter(c.color2);
                _extrafilters.push(f);
              }
            }
            if (iswarp) {
              let filter = c.yarntype && c.yarntype === 1 ? "url(#melangeFilter_warp)" : c.yarntype === 2 ? `url(#grindleFilter_${c.color2})` : c.yarntype === 3 ? `url(#sfdFilter_warp)` : null;
              let r = <rect x={x} y={"0"} key={`pattern_warp_rect0_${i}`} width={single ? "100%" : v * scalex} height={"100%"} fill={c.color ?? c} ref_i={`0_${i}`} filter={filter} />;
              x += v * scalex;
              real_x += real_v * scalex;
              let _c = warp_colors_ends[char];
              if (!_c) _c = 0;
              warp_colors_ends[char] = _c + real_v;
              return { r, data: { c, d, v, real_v } };
            } else {
              let filter = c.yarntype && c.yarntype === 1 ? "url(#melangeFilter_weft)" : c.yarntype === 2 ? `url(#grindleFilter_${c.color2})` : c.yarntype === 3 ? `url(#sfdFilter_weft)` : null;
              let r = <rect x="0" y={y} key={`pattern_weft_rect1_${i}`} width={"100%"} height={single ? "100%" : v * scaley} fill={c.color ?? c} ref_i={`1_${i}`} filter={filter} />;
              y += v * scaley;
              real_y += real_v * scaley;
              return { r, data: { c, d, v, real_v } };
            }
          }
        }
      };

      var warp_points = [];
      var weft_points = [];
      var warp_data = [];
      var weft_data = [];
      var warp_first;
      var weft_first;

      patternDATA.every((d, i) => {
        let warp = d[0];
        let weft = d[1];

        if (!warp && !weft) return false;

        if (warp) {
          let p = drawPattern(warp, true, i, i === 0 && !patternDATA[1][0]);
          if (p) {
            if (!warp_first) warp_first = (p.r ?? p.m ?? p.s).props.width;
            warp_points.push(p.r);
            warp_data.push(p.data);
          }
        }

        if (weft) {
          let p = drawPattern(weft, false, i, i === 0 && !patternDATA[1][1]);
          if (p) {
            if (!weft_first) weft_first = p.r.props.height;
            weft_points.push(p.r);
            weft_data.push(p.data);
          }
        }
        return true;
      });

      setextrafilters(_extrafilters.length ? _extrafilters : null);

      let s1 = epi / cadconfig.reed;
      let p_warp = (
        <pattern id="warp_pattern" width={warp_points.length === 1 ? "100%" : x} height={"100%"} patternUnits="userSpaceOnUse" patternTransform={`scale(${s1} 1)`}>
          {warp_points}
        </pattern>
      );

      let s2 = epi / cadconfig.pick;
      let p_weft = (
        <pattern id="weft_pattern" width={"100%"} height={weft_points.length === 1 ? "100%" : y} patternUnits="userSpaceOnUse" patternTransform={`rotate(180) scale(1 ${s2})`}>
          {weft_points}
        </pattern>
      );

      const warp_width = x * (epi / cadconfig.reed);
      const weft_width = y * (epi / cadconfig.pick);

      const _x = x / cadconfig.reed;
      const _y = y / cadconfig.pick;
      const warp_repeat_title = `${real_x} ends (or) ${_x.toFixed(2)} in`;
      const weft_repeat_title = `${real_y} picks (or) ${_y.toFixed(2)} in`;

      if (draw) {
        setcadconfig((d) => {
          d.real_x = real_x;
          d.real_y = real_y;
          d.warp_width = warp_width;
          d.weft_width = weft_width;
          d.warp_first = warp_first;
          d.weft_first = weft_first;
          d.warp_repeat_title = warp_repeat_title;
          d.weft_repeat_title = weft_repeat_title;

          d.warp_colors_ends = warp_colors_ends;
          return d;
        });
        setpattern_warp(p_warp);
        setpattern_weft(p_weft);
      }

      return { warp_points, weft_points, p_warp, p_weft, real_y, real_x, warp_first, weft_first, warp_width, weft_width, warp_repeat_title, weft_repeat_title, warp_data, weft_data };
    }
  };

  const getColorAtIndex = (i, char, index) => {
    if (typeof index === "number" && colorwaysDATA && colorwaysDATA.length > 0) {
      let c = colorwaysDATA[index];
      if (c && c.DATA) {
        let d = c.DATA.find((r) => r.char === char);
        if (d && d.color) return d;
      }
    }
    if (typeof i === "number") return tempColors[i];
    return null;
  };

  const getFile = useCallback(() => {
    let _patternDATA = [];

    let _weaveDATA;
    if (refreshAction && typeof refreshAction === "function") {
      _weaveDATA = refreshAction();
      setrefreshAction(null);
    }

    patternDATA.every((d, i) => {
      let warp = d[0];
      let weft = d[1];
      if (!warp && !weft) {
        return false;
      }
      _patternDATA.push(d);
      return true;
    });

    return { patternDATA: _patternDATA, colorwaysDATA, selected_colorwayIndex, weaveDATA: _weaveDATA ?? weaveDATA, cadconfig, erpDATA, dobbyDATA: _weaveDATA ?? dobbyDATA };
  }, [cadconfig, colorwaysDATA, erpDATA, patternDATA, selected_colorwayIndex, weaveDATA, dobbyDATA, refreshAction]);

  const saveFile = useCallback(
    (_, silent) => {
      let d = getFile();
      return (
        props.invoke &&
        props
          .invoke("menu_save", d)
          .then((res) => {
            if (res) {
              setcadconfig((d) => {
                d.filename = res;
                if (!d.stylename) d.stylename = res;
                return d;
              });
              !silent && Swal.fire({ toast: true, icon: "success", title: `Saved Successfully`, position: "top-end", showConfirmButton: false, timer: 3000, timerProgressBar: true });
              return d;
            } else {
              throw new Error(`"Save Error`);
            }
          })
          .catch((err) => {
            !silent && Swal.fire({ toast: true, icon: "error", title: `Unable to Save`, position: "top-end", showConfirmButton: false, timer: 3000, timerProgressBar: true });
            return err;
          })
      );
    },
    [getFile]
  );

  const renameFile = useCallback(
    (args) => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        position: "center",
        icon: "warning",
        title: "Rename File",
        input: "text",
        inputValue: cadconfig.filename,
        backdrop: true,
        showConfirmButton: true,
        showCloseButton: true,
        showCancelButton: true,
        allowEscapeKey: true,
        allowEnterKey: true,
        focusConfirm: true,
        preConfirm: (value) => {
          if (value) {
            //if (/[A-z0-9\s]/.test(value) === false) Swal.showValidationMessage(`Only alphanumberic characters are allowed`);
            return value;
          } else Swal.showValidationMessage(`Enter File Name`);
        },
      }).then((r) => {
        if (r.isConfirmed) {
          return (
            props.invoke &&
            props
              .invoke("menu_rename", r.value)
              .then((res) => {
                if (res) {
                  setcadconfig((d) => {
                    d.filename = res;
                    return d;
                  });
                  Swal.fire({ toast: true, icon: "success", title: `Rename Successfully`, position: "top-end", showConfirmButton: false, timer: 3000, timerProgressBar: true });
                  return true;
                } else {
                  throw new Error(`"Save Error`);
                }
              })
              .catch((err) => {
                Swal.fire({ toast: true, icon: "error", title: `Unable to Rename`, position: "top-end", showConfirmButton: false, timer: 3000, timerProgressBar: true });
                return err;
              })
          );
        }
      });
    },
    [cadconfig.filename]
  );

  const openFile = (res) => {
    if (res && res.data) {
      let d = typeof res.data === "string" ? JSON.parse(res.data) : res.data;
      if (d) {
        setpatternDATA(d.patternDATA);
        setcolorwaysDATA(d.colorwaysDATA);
        setselected_colorwayIndex(d.selected_colorwayIndex);
        setweaveDATA(d.weaveDATA);
        if (d.dobbyDATA) setdobbyDATA(d.dobbyDATA);
        if (d.cadconfig) setcadconfig(d.cadconfig);
        if (d.erpDATA) seterpDATA(d.erpDATA);
      }

      if (res.filename) {
        setcadconfig((d) => {
          d.filename = res.filename;
          return d;
        });
      }
    }
  };
  //8.3inch x 11.7inch - A4
  const printAction = (print, w = 8.27, h, withextra, index, s1 = 1, return_img, margin = 0, detail, cad, png, landscape, offsetx = 0) => {
    return new Promise((resolve) => {
      let dpi = 300;
      // if (!h) h = Math.max(cadconfig.weft_width / scaley / c_pick, 2) + 1.8 + margin * 2;
      if (!h) h = Math.max(cadconfig.weft_width / scaley / 61, 2) + 1.8 + margin * 2;

      index = index ?? selected_colorwayIndex;
      if (margin) {
        w = w - margin * 2;
        h = h - margin * 2;
      }

      if (offsetx) w = w + offsetx;

      // const cad_width = w * c_reed;
      // const cad_height = h * c_pick;
      const cad_width = w * epi;
      const cad_height = h * epi;
      const ext = "png";
      let d = colorwaysDATA[index];
      let arr = get_patternAlphabets();

      let p_weft, p_warp;
      if (typeof index === "number") {
        let pattern = patternAction(false, index);
        p_warp = pattern.p_warp;
        p_weft = pattern.p_weft;
      } else {
        p_warp = pattern_warp;
        p_weft = pattern_weft;
      }

      let r = (
        <SVGCadView pattern_weave={pattern_weave} pattern_warp={p_warp} pattern_weft={p_weft} weaveDATA={weaveDATA} width={cad_width} height={cad_height} palette={d} warp={arr.warp} weft={arr.weft} repeatline={30} export={withextra} cadconfig={cadconfig} extrafilters={extrafilters}></SVGCadView>
      );
      const markup = ReactDOMServer.renderToStaticMarkup(r);
      const canvas = document.createElement("canvas");

      if (typeof print === "boolean") canvas.style.imageRendering = "pixelated";
      const base64doc = btoa(unescape(encodeURIComponent(markup)));
      const data = "data:image/svg+xml;base64," + base64doc;

      const img_to_download = document.createElement("img");
      img_to_download.src = data;
      img_to_download.onload = function () {
        if (offsetx) w = w - offsetx;
        console.log("img loaded");
        canvas.setAttribute("width", (w + margin * 2) * dpi * s1);
        canvas.setAttribute("height", (h + margin * 2) * dpi * s1);
        canvas.style.width = w + margin * 2;
        canvas.style.height = h + margin * 2;
        const context = canvas.getContext("2d");
        context.fillStyle = "white";
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.scale(s1, s1);
        context.imageSmoothingQuality = "high";

        if (offsetx) {
          context.drawImage(img_to_download, offsetx * cadconfig.reed, 0, img_to_download.width - offsetx * epi, img_to_download.height, margin * dpi, margin * dpi, canvas.width - margin * 2 * dpi, canvas.height - margin * 2 * dpi);
        } else {
          context.drawImage(img_to_download, margin * dpi, margin * dpi, w * dpi, h * dpi);
        }

        context.fillStyle = "black";
        context.font = "20px Arial";
        let text = `Created in Fabrigram CAD • Copyright © 2022 Arthanari Loom Center Textile Pvt Ltd. India. • Created at ${new moment().format("lll")}`;
        if (appversion) text += ` • v${appversion}`;
        context.fillText(text, 85, 29);

        context.scale(1, 1);

        if (return_img && !detail) {
          canvas.toBlob((blob) => resolve({ blob, ext: "jpeg" }), `image/jpeg`, 0.4);
        } else {
          let dataURL = canvas.toDataURL(`image/${ext}`, 1);
          dataURL = changeDpiDataUrl(dataURL, dpi);
          let title = exporttitleAction(null, detail);
          if (png) {
            const a = document.createElement("a");
            const my_evt = new MouseEvent("click");
            a.download = `${title}.${ext}`;
            a.href = dataURL;
            a.dispatchEvent(my_evt);
            // if (print) {invoke("print_dataurl", { dataURL, ext })}
          } else {
            let obj = { dataURL, ext, print, title, return_img, landscape };
            if (detail) {
              let e = props.getCADSheet(cad, index, h, landscape);
              // let e = <CADSheet cad={cad} index={index} image_inch={h} landscape={landscape} />;
              obj.markup = ReactDOMServer.renderToStaticMarkup(e);
              // showConfirm(null, e);
            }
            if (return_img) {
              return resolve(obj);
            } else {
              props.invoke &&
                props.invoke("print_markup", obj).then((file) => {
                  filedownloadAction(null, file);
                });
            }
          }
          if (!return_img) return resolve({ dataURL, ext });
        }
      };
    });
  };

  const filedownloadAction = useCallback(
    (args, data) => {
      const filename = getfilename(data);
      Swal.fire({ toast: true, title: `File Downloaded`, text: `${filename}`, icon: "success", position: "top-end", timer: 3000, timerProgressBar: true, confirmButtonText: "Open" }).then((res) => {
        if (res.isConfirmed) {
          props.invoke && props.invoke("open_downloaded", data);
        }
      });
    },
    [props]
  );

  const cadsheetAction = (cad, all) => {
    function execute(index) {
      //11.69
      return printAction(null, 8.27, 7.5, true, index, 1, true, 0.12).then((res) => {
        let e = props.getCADSheet(cad, index, null, null, res.img); //<CADSheet cad={cad} img={res.img} index={index} />;
        return { markup: ReactDOMServer.renderToStaticMarkup(e), dataURL: res.dataURL };
      });
    }

    if (all) {
      //Incomplete - AAA
      // let promises = Array.from(Array(colorwaysDATA.length).keys()).map((p) => execute(p));
      // Promise.all(promises).then((res) => {
      //   let markup = res.reduce((p, c) => p + c, "");
      //   let title = exporttitleAction(null, false, true);
      //   invoke("print_markup", { markup, title });
      // });
    } else {
      execute(selected_colorwayIndex).then((d) => {
        let title = exporttitleAction(selected_colorwayIndex, true);
        props.invoke && props.invoke("print_markup", { markup: d.markup, dataURL: d.dataURL, title });
      });
    }
  };

  const exporttitleAction = (index, detail, group) => {
    if (!index) index = selected_colorwayIndex;
    const d = colorwaysDATA[index];
    const f = cadconfig.filename ?? "cad";
    if (group) return f + "_grouped";
    let title = (f + "_" + ((d && d.headDATA && d.headDATA.title) ?? `colorway${index + 1}`)).replace(/ /g, "_");
    if (detail) {
      title += "_detail";
    }
    return title;
  };

  const deSelectAllCellsAction = () => {
    const temp = document.body.querySelectorAll('rect[class="grayscale-100"]');
    for (var i = 0, il = temp.length; i < il; i++) temp[i].classList.remove("grayscale-100");
  };

  const convertunit_patternDATA = (k) => {
    let data = [...patternDATA];
    let old = cadconfig.unit;
    data.forEach((row, i) => {
      row.forEach((r, j) => {
        if (r) {
          let char = r.charAt(0);
          if (char !== "X") {
            let e = j === 0 ? cadconfig.reed : cadconfig.pick;
            let v = parseFloat(r.substring(1));
            if (v) {
              const res = convertunit(v, old, k, e);
              data[i][j] = char + res;
            }
          }
        }
      });
    });

    setcadconfig((d) => {
      d.unit = k;
      return { ...d };
    });

    setpatternDATA([...data]);

    return data;
  };

  useEffect(() => {
    props.invoke && props.invoke("appversion").then((v) => setappversion(v));
  }, []);

  useEffect(() => {
    let r = props.ipcRenderer;
    if (r) {
      r.on("menu_save", saveFile);
      r.on("menu_rename", renameFile);
      r.on("file_download", filedownloadAction);
    } else {
      setzoom(1);
    }

    return () => {
      if (r) {
        r.removeListener("menu_save", saveFile);
        r.removeListener("menu_rename", renameFile);
        r.removeListener("file_download", filedownloadAction);
      }
    };
  }, [filedownloadAction, props.ipcRenderer, renameFile, saveFile]);

  return (
    <CadContext.Provider
      value={{
        patternDATA,
        setpatternDATA,
        get_patternAlphabets,
        setcolorwaysDATA,
        colorwaysDATA,
        charDATA,
        selected_colorwayIndex,
        setselected_colorwayIndex,
        getFile,
        saveFile,
        renameFile,
        openFile,
        cadconfig,
        setcadconfig,
        setweaveDATA,
        printAction,
        patternAction,
        weaveDATA,
        erpDATA,
        seterpDATA,
        cadsheetAction,
        exporttitleAction,
        deSelectAllCellsAction,
        convertunit_patternDATA,
        zoom,
        setzoom,

        setdobbyDATA,
        dobbyDATA,

        setrefreshAction,
        refreshAction,

        getColorAtIndex,
      }}
    >
      <div className={"h-100 w-100 m-0 position-fixed p-0"}>
        {props.isElectron && (
          <>
            {refreshAction && (
              <div onClick={refreshAction} className={"cursor-pointer position-absolute w-100 h-100 opacity-50 bg-dark d-flex justify-content-around align-items-center text-white"}>
                <h4>Click to Refresh</h4>
                <h4>Click to Refresh</h4>
                <h4>Click to Refresh</h4>
              </div>
            )}
          </>
        )}
        {typeof zoom === "number" && <SVGCadView pattern_weave={pattern_weave} pattern_warp={pattern_warp} pattern_weft={pattern_weft} style={{ zoom: zoom }} cadconfig={cadconfig} weaveDATA={weaveDATA} extrafilters={extrafilters} />}
      </div>
      {props.children}
    </CadContext.Provider>
  );
};

export function SVGCadView(props) {
  let k = Math.random();

  let width = props.width ?? "100%";
  let height = props.height ?? "100%";
  let repeatline = props.repeatline ?? 0;
  let bottom = 58;
  let m_width = typeof width === "number" ? width : width;
  let m_height = typeof height === "number" ? height : height;
  const fs = 8;
  const fs2 = 6;
  const fs3 = 4;
  const tm = 10;

  function get_paletteui(arr, chars, y, title) {
    if (arr && arr.DATA) {
      let childs = chars.map((d, i) => {
        let obj = arr.DATA.find((_d) => d === _d.char);
        if (obj) {
          let tcolor = getContrast(obj.color);
          return (
            <g transform={`translate(${i * 54 + 32},${y + 0})`}>
              <rect x={0} y={0} width={38} height={17} fill={obj.color} filter={obj.yarntype === 1 ? "url(#melangeFilter_warp)" : obj.yarntype === 3 ? "url(#sfdFilter_warp)" : null} rx={2} stroke={obj.color === "#fff" ? "black" : null} strokeWidth={1} />
              {obj.color2 && <rect x={19} y={0} width={19} height={17} fill={obj.color2} rx={2} stroke={obj.color2 === "#fff" ? "black" : null} strokeWidth={1} />}

              <text fontSize={fs2} x={0} y={24} width={38} fontWeight={"bold"}>
                {obj.name && obj.name.slice(0, 15)}
              </text>
              {title === "WARP" && props.cadconfig.warp_colors_ends && props.cadconfig.warp_colors_ends[d] && (
                <text fontSize={fs2} x={2} y={5} alignmentBaseline="middle" fill={tcolor}>
                  {props.cadconfig.warp_colors_ends[d]}
                </text>
              )}

              {obj.yarntype > 0 && (
                <text fontSize={fs3} x={2} y={14} alignmentBaseline="middle" fill={tcolor}>
                  {obj.yarntype === 1 ? "Melange" : obj.yarntype === 2 ? "Grindle" : "SFD"}
                </text>
              )}

              {obj.upperbeam && (
                <text fontSize={fs3} x={32} y={14} alignmentBaseline="middle" fill={tcolor} fontWeight={"bold"}>
                  U
                </text>
              )}
            </g>
          );
        }
        return null;
      });
      return (
        <>
          {title && (
            <text fontSize={fs2} x={5} y={y + 5} width={"100%"} alignmentBaseline="hanging" fontWeight={"bold"}>
              {title}
            </text>
          )}

          {childs}
        </>
      );
    }
  }

  function turbulenceFilter(key, warp, a, t1, t2) {
    // let a = 0.5,
    // t1 = 0.02,
    // t2 = 0.9;
    return (
      <filter id={`${key}_${warp ? "warp" : "weft"}`} x={0} y={0}>
        <feTurbulence baseFrequency={!warp ? `${t1} ${t2}` : `${t2} ${t1}`} numOctaves={2} seed={3} xresult="colorNoise" stitchTiles="stitch" />
        <feColorMatrix in="colorNoise" type="matrix" values={`${a} ${a} ${a} 0 0 ${a} ${a} ${a} 0 0 ${a} ${a} ${a} 0 0 0 0 0 1 0`} />
        <feComposite operator="in" in2="SourceGraphic" result="monoNoise" />
        <feBlend in="SourceGraphic" in2="monoNoise" mode="multiply" />
      </filter>
    );
  }

  function melangeFilter(warp) {
    return turbulenceFilter("melangeFilter", warp, 0.5, 0.02, 0.9);
  }

  function sfdFilter(warp) {
    return turbulenceFilter("sfdFilter", warp, 0.8, 0.06, 0.9);
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} style={{ ...{ fontFamily: "Arial" }, ...props.style, background: "white" }} shapeRendering={props.export || props.crisp ? "crispEdges" : "auto"}>
      <defs>
        {props.pattern_weave}
        {props.pattern_warp}
        {props.pattern_weft}

        {!props.export && (
          <>
            <pattern id="repeatline_pattern" width={props.cadconfig.warp_width} height={props.cadconfig.weft_width} patternUnits="userSpaceOnUse">
              {props.cadconfig.warp_width >= 30 && <line x1={props.cadconfig.warp_width} y1={0} x2={props.cadconfig.warp_width} y2={props.cadconfig.weft_width} className="stroke-primary" strokeDasharray="6,6" />}
              {props.cadconfig.weft_width >= 30 && <line x1={0} y1={props.cadconfig.weft_width} x2={props.cadconfig.warp_width} y2={props.cadconfig.weft_width} className="stroke-primary" strokeDasharray="6,6" />}
            </pattern>
          </>
        )}
        <rect id="weave_fill" x={0} y={0} width={m_width} height={m_height} fill="url(#weave_pattern)" />
        <rect id="warp_fill" x={0} y={0} width={m_width} height={m_height} fill="url(#warp_pattern)" />
        <rect id="weft_fill" x={0} y={0} width={m_width} height={m_height} fill="url(#weft_pattern)" />
        {/* filterUnits="userSpaceOnUse" primitiveUnits="userSpaceOnUse" */}
        <filter id="filter1" x={0} y={0} width={m_width} height={m_height}>
          <feImage xlinkHref="#weave_fill" result="A" />
          <feImage xlinkHref="#warp_fill" result="B" />
          <feImage xlinkHref="#weft_fill" result="C" />
          <feComposite in2="B" in="A" operator="xor" result="D" />
          <feComposite in2="C" in="D" operator="over" />
        </filter>
        {melangeFilter(true)}
        {melangeFilter(false)}
        {sfdFilter(true)}
        {sfdFilter(false)}
        {props.extrafilters}
      </defs>

      {/* <rect id="warp_fill" x={0} y={0} width={m_width} height={m_height} fill="url(#weft_pattern)" /> */}

      <rect key={k} width={"100%"} height={"100%"} filter="url(#filter1)" className={"svg-flicker"} />

      {props.export ? (
        <>
          {/* Weft Extras */}
          <rect x={m_width - repeatline} width={m_width} height={m_height - bottom} fill="white" />
          {props.cadconfig.real_y > 1 && (
            <>
              <rect x={m_width - 5 - repeatline / 2} y={0} width={repeatline / 2} height={props.cadconfig && props.cadconfig.weft_width} strokeWidth={1} stroke={"black"} fillOpacity="0.0" />
              <text fontSize={6} fontWeight={"bold"} x={m_width + 5 - repeatline / 2} y={props.cadconfig && Math.max(40, props.cadconfig.weft_width / 2)} alignmentBaseline="middle" textAnchor="middle" style={{ writingMode: "tb;", paintOrder: "stroke", stroke: "white", strokeWidth: "1px" }}>
                {props.cadconfig.weft_repeat_title}
              </text>
            </>
          )}
          <rect x={m_width - repeatline} width={repeatline / 2} height={m_height - bottom - repeatline} fill="url(#weft_pattern)" />

          {/* Warp Extras */}
          <rect y={m_height - bottom - repeatline} width={m_width} height={repeatline + 1} fill="white" />
          {props.cadconfig.real_x > 1 && (
            <>
              <rect x={0} y={m_height - bottom - 4 - repeatline / 2} width={props.cadconfig && props.cadconfig.warp_width} height={repeatline / 2} strokeWidth={1} stroke={"black"} fillOpacity="0.0" />
              <text fontSize={6} fontWeight={"bold"} x={props.cadconfig && Math.max(40, props.cadconfig.warp_width / 2)} y={m_height - bottom + 6 - repeatline / 2} alignmentBaseline="middle" textAnchor="middle" style={{ paintOrder: "stroke", stroke: "white", strokeWidth: "1px" }}>
                {props.cadconfig.warp_repeat_title}
              </text>
            </>
          )}
          <rect y={m_height - bottom - repeatline} width={m_width - repeatline} height={repeatline / 2} fill="url(#warp_pattern)" />

          {/* Title Extras */}
          {/* <image href="/alclogo.png" x={100} y={tm} height={30} width={30} /> */}
          <text fontSize={fs} fill="white" style={{ paintOrder: "stroke", stroke: "black", strokeWidth: "1px" }}>
            <tspan x={tm} y={tm}>
              Style : {props.cadconfig.stylename}
            </tspan>
            {props.palette && props.palette.headDATA && (
              <tspan x={tm} y={tm + 8}>
                {`Colorway : ${props.palette.headDATA.title}`}
              </tspan>
            )}
          </text>
          <text textAnchor="end" fontSize={fs} fill="white" style={{ paintOrder: "stroke", stroke: "black", strokeWidth: "1px" }}>
            <tspan x={m_width - repeatline - tm} y={tm}>
              {props.cadconfig.onloomreed && props.cadconfig.onloompick && `OL Reed : ${props.cadconfig.onloomreed} • OL Pick : ${props.cadconfig.onloompick} / `}
              EPI : {props.cadconfig.reed} • PPI : {props.cadconfig.pick}
            </tspan>
            <tspan x={m_width - repeatline - tm} y={tm + 8}>
              Weave : {props.weaveDATA.name}
            </tspan>
          </text>
          {props.palette && (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" y={`${m_height - bottom}`} height={bottom} width={"100%"}>
                <rect width={"100%"} height={"100%"} fill={"white"} shapeRendering={"crispEdges"} />
                {get_paletteui(props.palette, props.warp, 2, "WARP")}
                {get_paletteui(props.palette, props.weft, 33, "WEFT")}
              </svg>
            </>
          )}
        </>
      ) : (
        <>
          <rect x={0} y={0} width={"100%"} height={"100%"} fill="url(#repeatline_pattern)" />
        </>
      )}
    </svg>
  );
}
export function getfilename(d) {
  let split = (d && d.split && d.split("/")) ?? "file";
  let filename = split[split.length - 1].split(".")[0];
  return filename;
}
export const expandDentAction = (dentrepeat, pegArray, dent, pegrepeat) => {
  let pegArraytoDentArray = [];

  let pegPlnRepeatArr = [];
  if (pegrepeat && Array.isArray(pegrepeat)) {
    let tmpPegPlnDetArr = [];
    let rev_pegArray = pegArray.slice().reverse();
    let rev_pegrepeat = pegrepeat.slice().reverse();

    rev_pegrepeat.forEach((val, i) => {
      if (val === 0) tmpPegPlnDetArr.push(rev_pegArray[i]);
      else {
        tmpPegPlnDetArr.push(rev_pegArray[i]);
        for (var j = 0; j < val; j++) {
          tmpPegPlnDetArr.forEach((k) => {
            pegPlnRepeatArr.push(k);
          });
        }
        tmpPegPlnDetArr = [];
      }
    });
    pegPlnRepeatArr.reverse();
  }

  const _peg = pegPlnRepeatArr.length ? pegPlnRepeatArr : pegArray;
  _peg.forEach((i) => {
    let arr = [];
    dent.forEach((k) => {
      arr.push(i[k - 1]);
    });
    pegArraytoDentArray.push(arr);
  });

  let finalarr = [];
  pegArraytoDentArray.forEach((i) => {
    let arr = [];
    let arr1 = [];
    dentrepeat.forEach((dentrepeatval, j) => {
      arr.push(i[j]);
      if (dentrepeatval > 0) {
        for (var l = 0; l < dentrepeatval; l++) {
          arr1.push(...arr);
        }
        arr = [];
      }
    });

    let dentcount = i.length;
    let dentrepeatcount = dentrepeat.length;
    for (var m = 0; m < dentcount; m++) {
      if (m > dentrepeatcount - 1) {
        arr1.push(i[m]);
      }
    }
    finalarr.push(arr1);
    arr1 = [];
  });

  return finalarr;
};

export function convertunit(v, o, n, e, round) {
  if (v) {
    if (o === "in") v = Math.round(v * e);
    else if (o === "cm") v = Math.round((v * e) / 2.54);
    else if (o === "mm") v = Math.round((v * e) / 2.54 / 10);

    if (n === "in") v = v / e;
    else if (n === "cm") v = (v / e) * 2.54;
    else if (n === "mm") v = (v / e) * 2.54 * 10;

    if (round) v = Math.round((v + Number.EPSILON) * 100) / 100; //roundNUM(v);
    return v;
  }
}

export const getContrast = (hexcolor, threshold) => {
  if (!hexcolor) return "black";
  if (hexcolor.slice(0, 1) === "#") hexcolor = hexcolor.slice(1);
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      .map(function (hex) {
        return hex + hex;
      })
      .join("");
  }
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= (threshold ?? 128) ? "black" : "white";
};

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const hexToColorizedColorMatrix = (hex) => {
  const rgb = hexToRgb(hex);
  if (!rgb) return null;

  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  const returnMatrix = [
    [r, 0, 0, 0, 0], // red
    [0, g, 0, 0, 0], // green
    [0, 0, b, 0, 0], // blue
    [0, 0, 0, 1.5, 0], // multiplyer
  ];
  return returnMatrix.join(",", " ");
};
