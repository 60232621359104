import { useState } from "react";
import { Spinner } from "react-bootstrap";

export default function LazyImage(props) {
  let [loaded, setloaded] = useState();

  return (
    <div className={"position-relative bg-body"} style={props.height && { height: props.height }}>
      {!loaded && (
        <div className={"h-100 w-100 position-absolute top-0 left-0 d-flex"}>
          <Spinner className={"m-auto"} variant={"dark"} animation={"border"} role={"status"} size="sm"></Spinner>
        </div>
      )}
      <img height={props.height} className={`d-block w-100 object-fit-cover opacity-transition ${props.src && loaded && `opacity-100`}`} src={props.src} alt={``} loading={"lazy"} onLoad={() => setloaded(true)} />
    </div>
  );
}
