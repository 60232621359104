import { useContext, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { fetchFabLibraryFolderImagePath, fetchFabricLibrary, fetchOBDetailsStocks, fetchOBDetailsTags, fetch_sql_collectiondetail_DATA, imgdatabase } from "../utility/fetcher";
import { getDICTwith_ID, getIDs2 } from "../utility/helpers";
import { ComboSwatchCardContainer } from "./ComboSwatchCard";
import EmptyView from "./EmptyView";
import { FirebaseContext } from "./FirebaseContext";
import SwatchCard from "./SwatchCard";

export default function SwatchCardContainer(props) {
  let fire = useContext(FirebaseContext);
  const [OrderBookDet_ids, setOrderBookDet_ids] = useState(props.OrderBookDet_ids);

  const enabled = useMemo(() => typeof OrderBookDet_ids === "string", [OrderBookDet_ids]);
  const isStockStory = useMemo(() => props.pDATA && props.pDATA.isStockStory, [props.pDATA]);

  let _query = useMemo(() => {
    if (props.pDATA && props.pDATA.StoryQueryString && isStockStory) {
      const query = JSON.parse(props.pDATA.StoryQueryString);
      return query;
    }
    return null;
  }, [isStockStory, props.pDATA, props.pDATA && props.pDATA.StoryQueryString]);

  const sql_collectiondetail_DATA = Sql_CollectionDetail_DATA(props, OrderBookDet_ids, isStockStory, enabled || isStockStory === true || typeof props.query === "object");
  const sql_collectionimages_DATA = Sql_CollectionImages_DATA(OrderBookDet_ids, enabled);
  const sql_tags_DATA = useQuery(`sql_tags_DATA_${OrderBookDet_ids}`, () => fetchOBDetailsTags(OrderBookDet_ids), { enabled });
  // const sql_stocks_DATA = useQuery(`sql_stocks_DATA_${OrderBookDet_ids}_${fire.isinternaluser()}`, () => (OrderBookDet_ids ? fetchOBDetailsStocks(OrderBookDet_ids, props.pDATA && props.pDATA.isStockStory ? "Y" : "N", fire.isinternaluser() ? "Y" : "N") : null), { enabled });
  const sql_stocks_DATA = useQuery(`sql_stocks_DATA_${OrderBookDet_ids}_${fire.isinternaluser()}`, () => (OrderBookDet_ids ? fetchOBDetailsStocks(OrderBookDet_ids, props.pDATA && props.pDATA.isStockStory ? "Y" : "N", fire.isinternaluser() ? "Y" : "N", _query) : null), { enabled });

  useEffect(() => {
    if (props.OrderBookDet_ids) {
      setOrderBookDet_ids(props.OrderBookDet_ids);
    }
  }, [props.OrderBookDet_ids]);

  useEffect(() => {
    if ((isStockStory || props.query) && sql_collectiondetail_DATA.isSuccess) {
      let ids = getIDs2(sql_collectiondetail_DATA.data, "OrderBookDet_id").join(",");
      setOrderBookDet_ids(ids);
    }
  }, [isStockStory, props.query, sql_collectiondetail_DATA.isSuccess, sql_collectiondetail_DATA.data]);

  // useEffect(() => {
  //   //if (props.OrderBookDet_ids) setOrderBookDet_ids(props.OrderBookDet_ids);
  //   if (props.pDATA && props.pDATA.isStockStory) {
  //     const query = JSON.parse(props.pDATA.StoryQueryString);
  //     fetch_sql_collectiondetail_DATA(query).then((res) => {
  //       // let res = res.sort((l, r) => {
  //       //   return new Date(r.ReceivedDate) - new Date(l.ReceivedDate);
  //       // });
  //       let ids = getIDs2(res, "OrderBookDet_id").join(",");
  //       queryClient.setQueryData(`sql_collectiondetail_DATA`, () => res);
  //       setOrderBookDet_ids(ids);
  //     });
  //   } else if (props.sqlDATA) {
  //     queryClient.setQueryData(`sql_collectionimages_DATA_1`, (_) => {
  //       let ids = getIDs2(props.sqlDATA, "OrderBookDet_id").join(",");
  //       setOrderBookDet_ids(ids);
  //       return props.sqlDATA;
  //     });
  //   }
  // }, [props.OrderBookDet_ids]);

  const ui_swatchcards = () => {
    var arr = [];
    if (props.isLoading || sql_collectiondetail_DATA.isLoading) {
      for (let i = 0; i < 4; i++) {
        arr.push(
          <Col key={`ui_swatchcard_isLoading${i}`}>
            <SwatchCard isLoading />
          </Col>
        );
      }
    } else if (sql_collectiondetail_DATA.data) {
      if (sql_collectiondetail_DATA.data.length === 0) return <EmptyView title={"No Articles Found"} />;
      arr = sql_collectiondetail_DATA.data.map((d, i) => {
        let gdict, tagsdict, stocksdict, imagesarr, images3darr, combos;
        if (props.gDATA) gdict = getDICTwith_ID(props.gDATA, d.OrderBookDet_id, "OrderBookDet_id");
        if (sql_tags_DATA.data) tagsdict = getDICTwith_ID(sql_tags_DATA.data, d.OrderBookDet_id, "OrderBookDet_id");
        if (sql_stocks_DATA.data) stocksdict = getDICTwith_ID(sql_stocks_DATA.data, d.OrderBookDet_id, "OrderBookDet_id");

        if (sql_collectionimages_DATA.isSuccess && sql_collectionimages_DATA.data.length === 2) {
          let _key = d.OrderCode.replace("/", "_");
          let images = sql_collectionimages_DATA.data[0];
          let threedimages = sql_collectionimages_DATA.data[1];
          if (images && images[_key]) {
            imagesarr = images[_key];
          } else if (d.ImagePath) {
            imagesarr = [d.ImagePath];
          }

          if (threedimages) {
            images3darr = threedimages[_key];
          }
        }

        if (Array.isArray(props.cDATA) && props.cDATA.length > 0 && gdict) {
          combos = props.cDATA.filter((d) => d._id === gdict._id);
        }

        let hasCombo = combos && combos.length > 0;
        return (
          <>
            <Col key={`ui_swatchcard${i}`} className={`${hasCombo && "w-100"}`}>
              <SwatchCard dict={d} gdict={gdict} pdict={props.pDATA} tagsdict={tagsdict} stocksdict={stocksdict} imagesarr={imagesarr} images3darr={images3darr} />
            </Col>
            {hasCombo && <ComboSwatchCardContainer data={combos} dict={d} />}
          </>
        );
      });
    } else if (enabled) {
      arr.push(<EmptyView title={"No Articles Found"} />);
    }
    return (
      <Row xs={1} md={2} className={"g-3 py-2"}>
        {arr}
      </Row>
    );
  };

  return ui_swatchcards();
}

export function Sql_CollectionDetail_DATA(props, OrderBookDet_ids, isStockStory, enabled) {
  return useQuery(
    `sql_collectiondetail_DATA_${isStockStory ? props.pDATA._id : props.query ? props.id : OrderBookDet_ids}`,
    () => {
      if (isStockStory) {
        const query = JSON.parse(props.pDATA.StoryQueryString);
        return fetch_sql_collectiondetail_DATA(query).then((res) => {
          return res.sort((l, r) => {
            if (r.ReceivedDate && l.ReceivedDate) {
              let rd = new Date(r.ReceivedDate);
              let ld = new Date(l.ReceivedDate);
              if (rd && ld) return rd - ld;
            }
            return false;
          });
        });
      } else if (props.query) {
        return fetchFabricLibrary(props.query);
      } else if (OrderBookDet_ids) {
        return fetch_sql_collectiondetail_DATA(OrderBookDet_ids);
      } else {
        return null;
      }
    },
    { enabled }
  );
}

export function Sql_CollectionImages_DATA(OrderBookDet_ids, enabled) {
  return useQuery(`sql_collectionimages_DATA_${OrderBookDet_ids}`, () => fetchFabLibraryFolderImagePath(`${OrderBookDet_ids}`), { enabled });
}
