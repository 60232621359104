import { Col, Container, ListGroup, Row, Button, Stack, CloseButton, InputGroup, FormControl, Spinner } from "react-bootstrap";
import { PaginationView } from "../components/PaginationView";
import { useQueries, useQuery } from "react-query";
import { fetchFabricLibrary, fetchFilterDetails } from "../utility/fetcher";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FirebaseContext } from "../components/FirebaseContext";
import EmptyView from "../components/EmptyView";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import LoadingView from "../components/LoadingView";
import IndexPage from "./IndexPage";
import SwatchCardContainer from "../components/SwatchCardContainer";
import { scrollToTop } from "../utility/helpers";
import { ref } from "@firebase/storage";
import { BiSearch } from "react-icons/bi";
import SearchInputView from "../components/SearchInputView";

export const HomePageContext = createContext(null);

export function HomePageProvider(props) {
  const [filterDATA, setfilterDATA] = useState({});
  const [filter1, setfilter1] = useState(); //FilterView
  const [filter1_str, setfilter1_str] = useState(); //FilterView
  const [filter2, setfilter2] = useState(); //HomeView
  const [filter2_str, setfilter2_str] = useState(); //HomeView
  const [pageNumber, setpageNumber] = useState();
  const [searchStr, setsearchStr] = useState();

  let fire = useContext(FirebaseContext);
  //const [searchParams, setSearchParams] = useSearchParams();

  const getDefaultFilterDATA = (r) => {
    let data = { Store_id: "20" };
    data.IsInternalUser = fire.isinternaluser() ? "Y" : "N";
    data.YarnCountFlag = "AND BOTH";
    data.ColorFlag = "AND BOTH";
    data.OrderType_id = "0";
    data.OrderBy = "";
    if (searchStr) {
      data.searchstr = searchStr;
    }
    if (r) {
      data.ReqType = "SELECT_FILTER";
    } else {
      const _pageNumber = (pageNumber && parseInt(pageNumber)) ?? 1;
      data.SNO = String((_pageNumber - 1) * 30);
      data.NoofRecords = "30";
      data.ReqType = "SELECT_FABRICRAM";
      //data.IsRemoved = "Y"; //AAA - 30th Mar
      // data.fromoz = "7"; //AAA - 30th Mar
      // data.tooz = "9"; //AAA - 30th Mar
    }
    return data;
  };

  const getFilterKeys = () => {
    let s = {};
    for (let [key, arr] of Object.entries(filterDATA)) {
      s[key] = arr.map((a) => a && a.id).join(",");
    }
    return s;
  };

  useEffect(() => {
    fire.setmiddleview(
      <div className={"ms-auto w-50"}>
        <SearchInputView
          onBlur={(e) => {
            let value = e.target.value;
            setsearchStr(value);
          }}
        />
      </div>
    );
    return () => {
      fire.setmiddleview(null);
    };
  }, []);

  useEffect(() => {
    let s = getFilterKeys();
    let def1 = { ...getDefaultFilterDATA(true), ...s };
    setfilter1(def1);
    setfilter1_str(JSON.stringify(def1));
    setpageNumber(1);
  }, [filterDATA, searchStr]);

  useEffect(() => {
    let s = getFilterKeys();

    // if (pageNumber) {
    //   s.page = String(pageNumber);
    // } else {
    //   delete s.page;
    // }

    let def2 = { ...getDefaultFilterDATA(false), ...s };
    setfilter2(def2);
    setfilter2_str(JSON.stringify(def2));
    scrollToTop();
  }, [pageNumber, filterDATA, searchStr]);

  const isChecked = (idfield_label, idfield, id) => {
    let res =
      filterDATA[idfield_label] &&
      filterDATA[idfield_label].findIndex((r) => {
        if (r) {
          let _id = r[idfield];
          if (_id) return _id === id;
        }
        return false;
      }) > -1;
    return res;
  };

  return (
    <HomePageContext.Provider value={{ filterDATA, setfilterDATA, filter1, filter2, filter1_str, filter2_str, pageNumber, setpageNumber, isChecked, getDefaultFilterDATA }}>
      <HomePage />
    </HomePageContext.Provider>
  );
}

export function HomePage(props) {
  let fire = useContext(FirebaseContext);
  let home = useContext(HomePageContext);
  let ref1 = useRef();

  const NoOfRecords = useQuery(
    `HomePage_NoOfRecords${home.filter1_str}`,
    () => {
      let d = { ...home.filter2, ...{ IsTotalCounts: "Y" } };
      delete d.SNO;
      //d.ReqType = "SELECT_FABRICRAM";
      delete d.NoofRecords;
      return fetchFabricLibrary(d).then((res) => {
        if (res) {
          let r = res[0];
          return r.NoOfRecords;
        }

        return 0;
      });
    },
    { enabled: typeof home.filter2 === "object" }
  );

  let access = useMemo(() => fire.isdomainuser(), [fire]);

  if (fire.userDATA_isLoading) {
    return <LoadingView key={"loading"} />;
  } else if (!fire.userDATA) {
    return <IndexPage />;
  } else if (fire.userDATA && !access) {
    return <IndexPage />;
  }

  return (
    <Container ref={ref1} fluid className={"mt-5 pt-3"}>
      <Row>
        <Col sm={2}>
          <FilterContainer />
        </Col>
        <Col>
          <h5 className={"m-2 d-flex justify-content-between lead"}>
            <div>{NoOfRecords.isLoading ? <Skeleton width={200} /> : <>{NoOfRecords.data && NoOfRecords.data > 0 ? `Page ${home.pageNumber} of ${Math.ceil(NoOfRecords.data / 30)}` : ``} </>}</div>
            <div>{NoOfRecords.isLoading ? <Skeleton width={200} /> : <>{NoOfRecords.data && NoOfRecords.data > 0 ? `${NoOfRecords.data} Articles` : ``} </>}</div>
          </h5>
          {!home.filter2 ? <SwatchCardContainer key={"HomePageView_SwatchCardContainer_Loading"} isLoading /> : <SwatchCardContainer key={`HomePageView_SwatchCardContainer_${home.filter2_str}`} query={home.filter2} id={home.filter2_str} />}
          <PaginationView key={`PaginationView1_${home.filter1_str}`} pageNumber={home.pageNumber} pageSize={30} totalCount={NoOfRecords.data} visiblePages={10} onPageChange={(i) => home.setpageNumber(i)} />
        </Col>
      </Row>
    </Container>
  );
}

function FilterContainer(props) {
  let home = useContext(HomePageContext);

  let params_str = home.filter1;
  const filter = home.filter1;
  const enabled = typeof filter === "object";
  let fire = useContext(FirebaseContext);
  const queries = useQueries(
    (() => {
      let internal = fire.isinternaluser();
      let queries = [];

      queries.push({
        queryKey: ["p_content", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTCONTENTCODE", filter).then((val) => {
            return { title: "Content", idfield: "YarnContent_id", idfield_label: "Content_id", titlefield: "YarnContent", data: val };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_warpcontent", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTWARPCONTENTCODE", filter).then((val) => {
            return { title: "Warp Content", idfield: "YarnContent_id", idfield_label: "WarpContent_id", titlefield: "YarnContent", data: val };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_weftcontent", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTWEFTCONTENTCODE", filter).then((val) => {
            return { title: "Weft Content", idfield: "YarnContent_id", idfield_label: "WeftContent_id", titlefield: "YarnContent", data: val };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_warpcount", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTWARPCOUNT", filter).then((val) => {
            return { title: "Warp Count", idfield: "YarnCount_id", idfield_label: "WarpCount_id", titlefield: "YarnCounts", titlefield2: "YarnContent", data: val };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_weftcount", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTWEFTCOUNT", filter).then((val) => {
            return { title: "Weft Count", idfield: "YarnCount_id", idfield_label: "WeftCount_id", titlefield: "YarnCounts", titlefield2: "YarnContent", data: val };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_weave", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTWEAVETYPE", filter).then((val) => {
            return { title: "Weave", idfield: "WeaveType_id", idfield_label: "WeaveType_id", titlefield: "WeaveType", data: val };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_finish", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTFINISHTYPE", filter).then((val) => {
            return { title: "Finish", idfield: "ProdGrp_id", idfield_label: "FinishType_id", titlefield: "ProdGroup", data: val };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_stock", params_str],
        queryFn: () =>
          new Promise((resolve) => {
            let val = [
              { id: "5", t: "5 +" },
              { id: "10", t: "10 +" },
              { id: "15", t: "15 +" },
              { id: "20", t: "20 +" },
              { id: "50", t: "50 +" },
              { id: "100", t: "100 +" },
              { id: "500", t: "500 +" },
              { id: "1000", t: "1000 +" },
              { id: "2000", t: "2000 +" },
            ];
            return resolve({ title: "Stock Meters", idfield: "id", titlefield: "t", idfield_label: "ExcessQty", data: val, ul_horizontal: true, flex_basis: 50, type: "radio", showmore: false });
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_weight", params_str],
        queryFn: () =>
          new Promise((resolve) => {
            return resolve({ title: "Weight", idfield: "id", titlefield: "t", type: "weight", idfield_label1: "FromGSM", idfield_label2: "ToGSM", idfield_label3: "FromOunce", idfield_label4: "ToOunce" });
          }),
        enabled,
      });

      if (internal) {
        queries.push({
          queryKey: ["p_brand", params_str],
          queryFn: () =>
            fetchFilterDetails("SELECTBRAND", filter).then((val) => {
              return { title: "Inspiration Brand", idfield: "Brand_id", idfield_label: "Brand_id", titlefield: "BrandName", data: val, type: "radio" };
            }),
          enabled,
        });

        queries.push({
          queryKey: ["p_buyer", params_str],
          queryFn: () =>
            fetchFilterDetails("SELECTBUYER", filter).then((val) => {
              return { title: "Buyer", idfield: "PartyBranch_id", idfield_label: "Buyer_id", titlefield: "PartyBranchName", data: val };
            }),
          enabled,
        });
      }

      queries.push({
        queryKey: ["p_season", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTSEASON", filter).then((val) => {
            return { title: "Season", idfield: "Season_id", idfield_label: "Season_id", titlefield: "Season", data: val };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_year", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTYEAR", filter).then((val) => {
            return { title: "Year", idfield: "Year_id", titlefield: "SeasonYear", idfield_label: "SeasonYear", data: val, type: "radio", showmore: false, ul_horizontal: true, flex_basis: 50 };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_designer", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTDESIGNER", filter).then((val) => {
            return { title: "Mill Merchandiser", idfield: "Employee_id", idfield_label: "Designer_id", titlefield: "EmpName", data: val, type: "radio" };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_warpcolor", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTWARPCOLOR", filter).then((val) => {
            return { title: "Warp Color Hue", idfield: "Color_id", idfield_label: "WarpColor_id", titlefield: "ColorName", data: val, colorfield: "ColorCode" };
          }),
        enabled,
      });

      queries.push({
        queryKey: ["p_weftcolor", params_str],
        queryFn: () =>
          fetchFilterDetails("SELECTWEFTCOLOR", filter).then((val) => {
            return { title: "Weft Color Hue", idfield: "Color_id", idfield_label: "WeftColor_id", titlefield: "ColorName", data: val, colorfield: "ColorCode" };
          }),
        enabled,
      });

      return queries;
    })()
  );

  if (queries.filter((q) => q.isLoading).length > 0) {
    return (
      <div className={"vh-100 d-flex justify-content-center align-items-center"}>
        <Spinner id={"spinner-filter"} key={"spinner-filter"} variant={"dark"} animation={"border"} role={"status"} className={"m-auto"}>
          <span className={"visually-hidden"}>Loading...</span>
        </Spinner>
      </div>
    );
  }

  const clearFilterAction = () => {
    home.setpageNumber(null);
    home.setfilterDATA({ ...{} });
  };

  return (
    <Container className={"p-0 mh-100"}>
      <div className={"d-flex justify-content-between"}>
        <h5 className={"m-2 lead"}>Filters</h5>
        {home.filterDATA && Object.keys(home.filterDATA).length > 0 && (
          <Button size={"sm"} variant={"link"} className={"text-secondary"} onClick={clearFilterAction}>
            Clear Filter
          </Button>
        )}
      </div>
      {queries.map((q) => (q.isSuccess && q.data && q.data.data && q.data.data.length > 0 ? <FilterView key={`filterview_${q.data.idfield_label}_${params_str}`} data={q.data} /> : null))}
    </Container>
  );
}

function FilterView(props) {
  const [showMore, setshowMore] = useState(false);
  const ref1 = useRef();
  let home = useContext(HomePageContext);

  const onChange = (e, d) => {
    let idfield_label = props.data.idfield_label;
    let idfield = props.data.idfield;
    d.id = d[idfield];
    if (e.target.checked) {
      if (!home.filterDATA[idfield_label]) {
        home.filterDATA[idfield_label] = [];
      }
      home.filterDATA[idfield_label].push(d);
    } else {
      if (home.filterDATA[idfield_label]) {
        let idx = home.filterDATA[idfield_label].findIndex((_d) => {
          if (_d && d) {
            return _d[idfield] === d[idfield];
          }
          return false;
        });
        if (idx > -1) home.filterDATA[idfield_label].splice(idx, 1);
      }

      if (home.filterDATA[idfield_label].length === 0) {
        delete home.filterDATA[idfield_label];
      }
    }

    home.setfilterDATA({ ...home.filterDATA });
  };

  const ui_get_list = () => {
    if (props.data.data) {
      let arr = [];
      if (props.data.data.every) {
        var data1 = props.data.data;
        if (props.search) {
          let l = props.search.toLowerCase();
          data1 = props.data.data.filter((d) => {
            let t = d[props.data.titlefield];
            return t && t.toLowerCase().includes(l);
          });
        }

        data1.every((d, i) => {
          if (i > 15 && !props.more) {
            arr.push(
              <Button
                className={"small m-0 p-0 mt-2 text-start"}
                variant={"link"}
                onClick={() => {
                  setshowMore(ref1.current.offsetTop);
                }}
              >
                + {data1.length - 15} more
              </Button>
            );
            return false;
          }
          const id = d[props.data.idfield];
          const f = `${props.data.title}_${id}`;
          arr.push(
            <ListGroup.Item className={`m-0 p-0 border-0 small text-truncate`} style={props.more && { flexBasis: "33.33%" }}>
              <input
                key={f}
                className={"me-2"}
                type="checkbox"
                checked={home.isChecked(props.data.idfield_label, props.data.idfield, id)}
                id={f}
                name={f}
                value={d[props.data.titlefield]}
                onChange={(e) => {
                  onChange(e, d);
                }}
              />
              <label className={"d-inline"} for={f}>
                {props.data.colorfield && <div style={{ width: "15px", height: "15px", backgroundColor: d[props.data.colorfield] }} className={"rounded-circle d-inline-block me-1"}></div>}
                {d[props.data.titlefield]}
                {d.DCOUNT && (
                  <small className={"ms-1 text-secondary"} style={{ fontSize: "10px" }}>
                    ({d.DCOUNT})
                  </small>
                )}
              </label>
            </ListGroup.Item>
          );
          return true;
        });
      }
      return arr;
    }
  };

  return (
    <>
      {!props.more && showMore && <FilterModelView data={props.data} more={true} top={showMore} onCloseClick={() => setshowMore(false)} />}
      <div ref={ref1} className={`px-3 py-2 overflow-auto ${props.more ? `` : ` m-2 shadow-sm rounded border border-light`}`}>
        {props.data && (
          <>
            {!props.more && <small className={"fw-bold"}>{props.data.title}</small>}
            <ListGroup className={`p-0 ${props.more && "flex-wrap flex-row"}`}>{ui_get_list()}</ListGroup>
          </>
        )}
      </div>
    </>
  );
}

function FilterModelView(props) {
  let [search, setsearch] = useState();

  return (
    <Container style={{ width: "800px", height: "450px", top: props.top }} className={"p-0 mx-2 d-flex flex-column bg-body shadow-lg rounded border border-light position-absolute left-0 z-index-1000"}>
      <Stack direction={"horizontal"} className={"w-100 border-bottom py-2 px-3"}>
        <h4 className={"me-auto my-0"}>{props.data.title}</h4>
        <InputGroup size={"sm"} className={"me-4 w-auto"}>
          <FormControl placeholder="Search" onChange={(e) => (e.target.value ? setsearch(e.target.value) : setsearch(null))} />
        </InputGroup>
        <div>
          <CloseButton onClick={props.onCloseClick} />
        </div>
      </Stack>
      <FilterView {...props} search={search} />
    </Container>
  );
}
