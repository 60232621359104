import { useContext } from "react";
import { Container, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FirebaseContext } from "./FirebaseContext";

export default function AboutView(props) {
  let fire = useContext(FirebaseContext);

  if (fire.userDATA_isLoading) {
    return null;
  }

  return (
    <Container data-html2canvas-ignore className={"py-5"}>
      <hr />
      <h5 className={"fw-bold"}>About Fabrigram</h5>
      <h6>
        Fabrigram is a digital fabric library of ALC. ALC is one of the Leading Woven Fabric Manufacturer in India.
        <br />
        ALC is working with most of the Leading Apparel Brands across the globe.
      </h6>
      <h6 className={"text-secondary"}>
        Copyright © 2022 Arthanari Loom Center Textile Pvt Ltd. India.{" "}
        <a className={"text-secondary"} href={"https://alctex.com"} target={"_blank"} rel="noreferrer">
          alctex.com
        </a>
      </h6>
      <Stack direction={"horizontal"} gap={2} className={"small mt-5"}>
        <Link to={"/terms"} target={"_blank"} className={"text-secondary"}>
          Terms of Service
        </Link>
        <Link to={"/policy"} target={"_blank"} className={"text-secondary"}>
          Privacy Policy
        </Link>
      </Stack>
    </Container>
  );
}
