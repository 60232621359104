import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Container, Stack, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { ColorCard } from "../components/ColorCard";
import { ColorPaletteView2 } from "../components/ColorPaletteView";
import EmptyView from "../components/EmptyView";
import { FirebaseContext } from "../components/FirebaseContext";
import LoadingView from "../components/LoadingView";
import SearchInputView from "../components/SearchInputView";
import { fetch_colorbook } from "../utility/fire_fetcher";

export function ColorBook(props) {
  const color_DATA = useQuery(`color_DATA`, () => fetch_colorbook());
  const fire = useContext(FirebaseContext);
  const [DATA, setDATA] = useState();
  const [selectedDATA, setselectedDATA] = useState([]);
  const [searchStr, setsearchStr] = useState();
  const [cleartemp, setcleartemp] = useState();

  let timerRef = useRef();

  const searchbox = useMemo(
    () => (
      <SearchInputView
        placeholder={"Search Color"}
        onKeyUp={(e) => {
          let value = e.target.value;
          setsearchStr(value);
        }}
      />
    ),
    []
  );

  useEffect(() => {
    let d = get_colorbook_selectedDATA();
    setselectedDATA(d);
  }, []);

  useEffect(() => {
    if (!props.modal) {
      fire.setmiddleview(<div className={"ms-auto w-50"}>{searchbox}</div>);
      return () => {
        fire.setmiddleview(null);
      };
    }
  }, [fire, props.modal, searchbox]);

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);

    if (color_DATA.isSuccess) {
      if (searchStr) {
        timerRef.current = setTimeout(function () {
          console.log("Called");
          let text = searchStr.replace(/\s+/g, "").toLowerCase();
          if (text.length > 0) {
            let arr = color_DATA.data.map((data) => {
              return data.filter((d) => {
                let values = [d.name, d.code];
                let res = false;
                values.every((v) => {
                  if (v && v.replace(/\s+/g, "").toLowerCase().includes(text)) {
                    res = true;
                    return false;
                  }
                  return true;
                });
                return res;
              });
            });
            setDATA(arr);
          } else setDATA(color_DATA.data);
        }, 1000);
      } else setDATA(color_DATA.data);
    }
  }, [color_DATA.data, color_DATA.isSuccess, searchStr, selectedDATA]);

  const doneAction = () => {
    set_colorbook_selectedDATA(selectedDATA);
    props.doneAction(selectedDATA);
  };

  const clearAction = () => {
    setselectedDATA((_) => []);
    set_colorbook_selectedDATA([]);
    setcleartemp(Math.random());
  };

  const onSelected = useCallback(
    (selected, d) => {
      if (props.single) {
        props.doneAction([d]);
        return;
      }

      if (selected) {
        selectedDATA.push(d);
      } else {
        let i = selectedDATA.findIndex((_d) => _d.code === d.code);
        if (i > -1) selectedDATA.splice(i, 1);
      }
      setselectedDATA([...selectedDATA]);
    },
    [props, selectedDATA]
  );

  const ui_colorswatchs = useMemo(() => {
    return (
      DATA &&
      DATA.map((arr, i) => {
        return (
          <>
            <h1 className="mt-3">{i === 0 ? "Pantone" : "Archroma"}</h1>
            {Array.isArray(arr) && arr.length ? (
              <div class="row g-2">
                {arr.map((d, j) => {
                  let k = selectedDATA.findIndex((_d) => _d.code === d.code);
                  const selected = !props.single && k > -1;
                  return <ColorCard id={`ColorBook_${d.code}`} key={`ColorBook_${d.code}_${selected}_${cleartemp}`} d={d} onSelected={onSelected} selected={selected} type={1} />;
                })}
              </div>
            ) : (
              "No Colors Found"
            )}
          </>
        );
      })
    );
  }, [DATA, cleartemp]);

  if (color_DATA.isError) return "Unable to Load DATA";
  if (color_DATA.isLoading) return <LoadingView key={"loading"} className={"py-5"} />;

  return (
    <Container className={`${!props.modal && `mt-5 pt-5`}`} style={{ paddingBottom: 200 }}>
      {props.modal && searchbox}
      {DATA && DATA.length === 2 && <>{DATA[0].length === 0 && DATA[1].length === 0 ? <EmptyView title={`No Colors Found ${searchStr && `for "${searchStr}"`}`} /> : <>{ui_colorswatchs}</>}</>}
      {!props.single && (
        <>
          {selectedDATA && selectedDATA.length > 0 && (
            <ColorPaletteView2
              data={selectedDATA}
              header={
                <Stack direction="horizontal" gap={2} className="mx-2 mt-2 o">
                  <Button variant="outline-dark" size="sm" onClick={doneAction}>
                    Done
                  </Button>
                  <Button variant="outline-dark" size="sm" onClick={clearAction}>
                    Clear
                  </Button>
                </Stack>
              }
            />
          )}
        </>
      )}
    </Container>
  );
}

export function set_colorbook_selectedDATA(d) {
  window.localStorage.setItem("colorbook_selectedDATA", JSON.stringify(d));
}

export function get_colorbook_selectedDATA() {
  let d = window.localStorage.getItem("colorbook_selectedDATA");
  if (d) return JSON.parse(d);
  return [];
}
