import { getDownloadURL, ref } from "@firebase/storage";
import { httpsCallable } from "firebase/functions";
import { alccloud_storageRef, default_storageRef, functions } from "../components/FirebaseContext";
import { imgdatabase } from "./fetcher";
import { BlobUrl, downloadURL, openNewWindow } from "./helpers";
const firebasecloud = "https://us-central1-alcfabriclibraryfirebase.cloudfunctions.net";

export function fetch_fire_collection_DATA(Id) {
  return httpsCallable(
    functions,
    "collections"
  )({ Id })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export function fetch_fire_collectiondetail_DATA(Id) {
  return httpsCallable(
    functions,
    "collection_detail"
  )({ Id, combos: false })
    .then((res) => res.data)
    .catch((err) => err);
}

export function downloadOBDetailImage(ImagePath) {
  if (ImagePath != null) {
    let d = ImagePath.substring(1); //Drop First Character
    return new Promise((resolve, reject) => {
      fire_downloadImage(alccloud_storageRef, d)
        .then(function (url) {
          resolve(url);
        })
        .catch(function (error) {
          if (error) {
            //Object not found., fallback to ALC Domain for the Image
            let path = ImagePath.replace("~", imgdatabase);
            console.log("Image Returned from ALC Domain -> " + path);
            resolve(path);
          } else {
            reject(false);
          }
        });
    });
  }
}

export function fire_downloadImage(storageRef, d) {
  return getDownloadURL(ref(storageRef ?? default_storageRef, d));
}

var fetchIngUserID = [];
var fetchIngUserDetails_CallBacks = [];

export function fetch_userDetails(id, completion) {
  if (id != null) {
    var fetchIngUID = fetchIngUserID[id];
    if (fetchIngUID != null && fetchIngUID.DATA != null) {
      completion(fetchIngUID.DATA);
    } else if (fetchIngUID != null && fetchIngUID.DATA == null) {
      fetchIngUserDetails_CallBacks.push({ uid: id, completion: completion });
    } else if (fetchIngUID == null) {
      fetchIngUserID[id] = "";
      fetchIngUserDetails_CallBacks.push({ uid: id, completion: completion });

      httpsCallable(
        functions,
        "get_uDetails"
      )({ Id: id })
        .then(function (result) {
          if (result.data.errorInfo != null) {
            console.log(result.data.errorInfo);
          } else {
            fetchIngUserID[id] = { DATA: result.data };
            var fillterList = fetchIngUserDetails_CallBacks.filter(function (dict) {
              return dict.uid === id;
            });

            for (var i = 0; i < fillterList.length; i++) {
              if (fillterList[i]["IsReturned"] == null || fillterList[i]["IsReturned"] == undefined) {
                var _completion = fillterList[i]["completion"];

                _completion(result.data);
                fillterList[i]["IsReturned"] = true;
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          completion(null);
        });
    }
  }
}

export function fetch_addShareWith(Id, sharedWith, groupDATA) {
  return httpsCallable(
    functions,
    "collection_sharedWith"
  )({ Id, sharedWith, groupDATA })
    .then((res) => res.data)
    .catch((err) => err);
}

export function fetch_fire_collection_likeAction(d) {
  return httpsCallable(
    functions,
    "collection_likeAction"
  )(d)
    .then((res) => res.data)
    .catch((err) => err);
}

export function fetch_ImageWithObDetails(d) {
  //json, filename, zip
  let jsonfin = encodeURIComponent(JSON.stringify(d.json));
  let url = firebasecloud + "/fetchOBStickerPDF?json=" + jsonfin;
  return BlobUrl(url, d.zip ? "application/zip" : "application/pdf").then((url) => {
    if (url.includes("alccloud")) {
      return openNewWindow(url);
    } else {
      return downloadURL(url, d.filename);
    }
  });
}

export function fire_fetch_SetPrice(d) {
  return httpsCallable(
    functions,
    "collections_price"
  )(d)
    .then((res) => res.data)
    .catch((err) => err);
}

export function fetch_ObGridPDF(d) {
  let jsonfin = encodeURIComponent(JSON.stringify(d.json));
  let url = firebasecloud + "/fetchOBGridPDF?json=" + jsonfin;
  return BlobUrl(url, "application/pdf").then((url) => {
    if (url.includes("alccloud")) {
      return openNewWindow(url);
    } else {
      return downloadURL(url, d.filename);
    }
  });
}

export function fetch_AllStories(arr) {
  return httpsCallable(
    functions,
    "fetchAllStories"
  )(arr)
    .then(function (result) {
      let data = result.data;
      data.sort((lhs, rhs) => {
        if (lhs.StoryIndex !== undefined && lhs.StoryIndex !== null && rhs.StoryIndex !== undefined && rhs.StoryIndex !== null) {
          if (!lhs.isStoryEnabled) {
            return 1;
          } else if (!rhs.isStoryEnabled) {
            return -1;
          }
          return lhs.StoryIndex - rhs.StoryIndex;
        }
        return -1000;
      });
      return data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

export function fetch_internaluser(Id) {
  if (!Id) return new Promise.reject(false);
  return httpsCallable(
    functions,
    "getFabusers"
  )({ Id })
    .then((res) => res.data)
    .catch((err) => err);
}

export function fetch_domainaccess(str) {
  if (!str) return new Promise.reject(false);
  return httpsCallable(
    functions,
    "domainAccess"
  )({ str })
    .then((res) => res.data)
    .catch((err) => err);
}

export function fetch_collectionsGroupCombos(Id, LikedBy) {
  let json = { Id, LikedBy };
  return httpsCallable(
    functions,
    "collectionsGroupCombos"
  )(json)
    .then((res) => {
      res.data = res.data.sort((lhs, rhs) => {
        let clhs = lhs.colorwayName;
        let crhs = rhs.colorwayName;
        if (clhs && crhs) {
          return clhs.localeCompare(crhs);
        }
        return false;
      });
      return res.data;
    })
    .catch((err) => err);
}

export function fetch_signup(email, text) {
  if (!email || !text) return new Promise.reject(false);
  let url = firebasecloud + "/set_domainAccess?data=";
  let url_insert = url + encodeURIComponent(JSON.stringify({ str: email, insert: true }));
  let url_delete = url + encodeURIComponent(JSON.stringify({ str: email, insert: false }));
  let json = {
    html: {
      url_insert,
      url_delete,
      text,
    },
  };

  return httpsCallable(
    functions,
    "telegramMessage"
  )(json)
    .then((res) => res.data)
    .catch((err) => err);
}

export function fetch_colorbook() {
  let p1, p2;

  const d1 = window.localStorage.getItem("colorbook_pantone");
  if (typeof d1 == "string") p1 = Promise.resolve(JSON.parse(d1));

  const d2 = window.localStorage.getItem("colorbook_archroma");
  if (typeof d2 == "string") p2 = Promise.resolve(JSON.parse(d2));

  const pp1 = httpsCallable(functions, "getPantoneDATA")()
    .then((res) => res.data.getBook.colors)
    .then((res) => {
      window.localStorage.setItem("colorbook_pantone", JSON.stringify(res));
      return res;
    });

  const pp2 = httpsCallable(functions, "getArchromaDATA")()
    .then((res) => res.data)
    .then((res) => {
      window.localStorage.setItem("colorbook_archroma", JSON.stringify(res));
      return res;
    });

  if (!p1) p1 = pp1;
  if (!p2) p2 = pp2;

  return Promise.all([p1, p2])
    .then((res) => res)
    .catch((err) => err);
}
