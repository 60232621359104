import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Timestamp } from "firebase/firestore";

export const swalToastProps = { toast: true, timer: 3000, position: "bottom-left", showConfirmButton: false, icon: "success" };
export const mutationDefaults = { onMutate: () => showloader(), onSuccess: () => showloader(true), onError: () => showloader(false) };
export const fabrigram_oldurl = "https://fabrigram2.web.app";
export const fabrigram_newurl = "https://fabrigram.com";

export function getIDs2(arr, parameter, unique) {
  let a = [];
  for (var i = 0; i < arr.length; i++) {
    const id = arr[i][parameter];
    if (id) a.push(id);
  }
  if (unique) return Array.from(new Set(a));
  return a;
}

export function getDICTwith_ID(data, id, idField) {
  for (var i = 0; i < data.length; i++) {
    let dict = data[i];
    if (String(dict[idField]) === String(id)) return dict;
  }
}

export function openNewWindow(url) {
  if (url === null) {
    return;
  }
  const newWin = window.open(url);
  if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
    // script_load_windowjs(function () {
    //   let box = new magixBOX({
    //     Title: "Alert !",
    //     Text: "Confirm to download/open link",
    //     Type: "confirm",
    //     CloseText: "Cancel",
    //     ConfirmText: "Done",
    //     Callback: function () {
    //       window.open(url);
    //       return true;
    //     },
    //   });
    //   box.init();
    // });
  }
}

export function BlobUrl(url, contenttype) {
  return new Promise((resolve, reject) => {
    let xhttp = new XMLHttpRequest();
    xhttp.open("POST", url, true);
    xhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
    //xhttp.setRequestHeader('Vary', 'Origin')
    xhttp.withCredentials = false;
    xhttp.responseType = "arraybuffer";
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          let str = arrayBufferToString(this.response);
          if (str !== undefined && str.includes("https")) {
            console.log("Returned Blob as URL String , calling Blob URL again.");
            return resolve(str);
          } else {
            // Obtain a blob: URL for the image data.
            var arrayBufferView = new Uint8Array(this.response);
            var blob = new Blob([arrayBufferView], {
              type: contenttype,
            });
            var urlCreator = window.URL || window.webkitURL;
            var url = urlCreator.createObjectURL(blob);
            return resolve(url);
          }
        } else {
          //Error
          return reject(new Error("BlobUrl Error"));
        }
      }
    };
    xhttp.send();
  });
}

function arrayBufferToString(buffer) {
  var arr = new Uint8Array(buffer);
  let str;
  try {
    str = String.fromCharCode.apply(String, arr);
  } catch (err) {
    if (/[\u0080-\uffff]/.test(str)) {
      str = "this string seems to contain (still encoded) multibytes";
    }
  }
  str = str === undefined ? "" : str;

  return str;
}

export function downloadURL(url, fileName, force) {
  return new Promise((resolve, reject) => {
    const link = document.createElement("a");
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      if (force) {
        let random = Math.random().toString(36).slice(2);
        if (url.includes("?")) {
          url = url + `&r=${random}`;
        } else {
          url = url + `?r=${random}`;
        }
      }
      link.setAttribute("href", url);
      // link.setAttribute('target','_blank')
      fileName = "ALC_" + (fileName ?? "file").replace(/[^a-z0-9]/gi, "_");
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      return resolve(true);
    }
    return reject(new Error("downloadURL Error"));
  });
}

export function getFileNameString(str) {
  return str.replace(/[^a-z0-9]/gi, "_");
}

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const showloader = (success, title) => {
  const MySwal = withReactContent(Swal);

  let d = {
    title: title ?? "Loading",
    toast: true,
    position: "bottom-left",
    showConfirmButton: false,
    iconHtml: (success === undefined || null) && <Spinner animation={"border"} role={"status"}></Spinner>,
    customClass: (success === undefined || null) && {
      icon: "custom-loader-icon",
    },
  };
  if (typeof success === "boolean") {
    d.timer = 3000;
    d.icon = success ? "success" : "error";
    d.animation = false;
    d.showClass = {
      popup: "",
    };
    d.title = success ? "Done :)" : "Error [X]";
  }
  MySwal.fire(d);
};

export const shareCollectionLinkAction = (fire, data, LikedBy) => {
  if (data) {
    const link = getCollectionLink(data, LikedBy);
    copyToClipboard(link);
    let d = { ...{ title: fire.get_welcomename(), text: "Copied link to Clipboard, Ctrl+V to Paste" }, ...swalToastProps };
    Swal.fire(d);
  }
};

export const getCollectionLink = (data, LikedBy) => {
  let link = `${fabrigram_newurl}/collection?Id=` + data._id;
  if (data.isStory === true) {
    link = link + "&isStory=true";
  }
  if (typeof LikedBy === "string") {
    link = link + "&LikedBy=" + LikedBy;
  }
  return link;
};

export const timestampToDate = (d) => {
  let ts = new Timestamp(d._seconds, d._nanoseconds);
  return ts.toDate();
};

export function kFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k" : Math.sign(num) * Math.abs(num);
}

export function scrollToTop() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "instant",
  });
}

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
}
