import { Button } from "bootstrap";
import { OverlayTrigger, Stack, Popover } from "react-bootstrap";
import { ColorCard } from "./ColorCard";

export function ColorPaletteView(props) {
  return (
    <>
      {props.colors && (
        <div className={`justify-content-center ${props.type !== 2 && `stack`} ${props.className}`}>
          {props.colors.map &&
            props.colors.map((d, i) => (
              <OverlayTrigger
                overlay={
                  <Popover id={`colorcard_popover${i}`}>
                    <Popover.Body className={"p-0"}>
                      <ColorCard d={d} modal />
                    </Popover.Body>
                  </Popover>
                }
              >
                <div className={`${props.type === 2 ? `` : ``} rounded-circle me-1 cursor-pointer d-inline-block`} style={{ width: props.type === 2 ? 27 : 17, height: props.type === 2 ? 27 : 17, backgroundColor: `#${d.hex}` }}></div>
              </OverlayTrigger>
            ))}
        </div>
      )}
    </>
  );
}

export function ColorPaletteView2(props) {
  return (
    <div className={`position-fixed border border-light bottom-0 start-50 translate-middle-x rounded-2 mb-4 w-75 shadow bg-body ${props.className}`} style={props.style}>
      {props.header}
      <div className="p-2">
        <Stack direction="horizontal" className="justify-content-start" gap={2}>
          {props.data.map((d, j) => {
            if (!d) return null;
            return (
              <ColorCard
                key={`ColorBook_${d.code}_selected`}
                d={d}
                className={"d-inline-block"}
                dropdownItems={props.dropdownItems && props.dropdownItems.length > j && props.dropdownItems[j]}
                type={2}
                onSelected={(s, d) => {
                  let c = document.getElementById(`ColorBook_${d.code}`);
                  if (c) c.scrollIntoView({ behavior: "instant", block: "center" });
                }}
              />
            );
          })}
        </Stack>
      </div>
    </div>
  );
}
