import { useContext, useMemo, useRef, useState } from "react";
import { Card, Button, Form, Stack, OverlayTrigger, Popover, Tooltip, Badge, Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { AiOutlineHeart, AiOutlineFileImage, AiFillHeart, AiFillFileImage } from "react-icons/ai";
import { IoShirtOutline, IoColorPaletteOutline } from "react-icons/io5";
import { BiError } from "react-icons/bi";

import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { queryClient } from "../App";
import { downloadOBDetailImage, fetch_fire_collection_likeAction, fetch_ImageWithObDetails, fire_fetch_SetPrice } from "../utility/fire_fetcher";
import { getFileNameString, kFormatter } from "../utility/helpers";
import { FirebaseContext } from "./FirebaseContext";
import FullScreenSwatchCard from "./FullScreenSwatchCard";
import LoadingView from "./LoadingView";
import { SharedWithContainer } from "./SharedWithView";
import { SwatchCardCarousel } from "./SwatchCardCarousel";
import { ThreeDView } from "./ThreeDView";

export default function SwatchCard(props) {
  const [overlayIcon, setoverlayIcon] = useState();
  const [selectedImageIndex, setselectedImageIndex] = useState(0);

  const [showFullScreen, setshowFullScreen] = useState(false);

  let overlayIconTimer = useRef();
  let fire = useContext(FirebaseContext);

  const like_Mutation = useMutation((d) => fetch_fire_collection_likeAction(d));

  const downloadOB_Mutation = useMutation((d) => fetch_ImageWithObDetails(d), {
    onSuccess: (data, variables, context) => {
      iconClickAction(AiFillFileImage);
    },
  });

  const price_Mutation = useMutation((d) => fire_fetch_SetPrice(d));

  const ui_get_fabricdetail = useMemo(() => {
    if (props.tagsdict) {
      let d = props.tagsdict;
      let subStr1 = [],
        subStr2 = [];
      if (d.Content) subStr1.push(d.Content);
      if (d.GSM && d.Ounce) subStr1.push(parseInt(Math.round(d.GSM)) + " GSM ⚬ " + Math.round((d.Ounce + Number.EPSILON) * 100) / 100 + " oz/yd");

      if (d.ProdGroup) subStr2.push(d.ProdGroup);
      if (d.WeaveType) subStr2.push(d.WeaveType);

      return (
        <>
          <small className={"d-block mt-2"}>
            {subStr1.join(" ⚈ ")}
            <br />
            {/* {r ? " ⚬ " : <br />} */}
            {subStr2.join(" ⚈ ")}
          </small>
          {props.tagsdict.TagsName && <small className={`d-block mt-2 text-muted`}>{props.tagsdict.TagsName}</small>}
        </>
      );
    }
  }, [props.tagsdict]);

  const ui_get_likedby = () => {
    if (props.gdict && props.gdict.LikedBy && props.gdict.LikedBy.length) {
      return (
        <div className={"border-top border-bottom mt-2 d-flex align-items-center"}>
          <Badge className={"me-2"} bg="danger">
            <AiFillHeart className={"me-1"} size={12} color={"white"} />
            Liked By
          </Badge>
          <SharedWithContainer small data={props.gdict.LikedBy} />
        </div>
      );
    }
    return null;
  };

  const get_likeByIndex = () => {
    if (props.gdict && props.gdict.LikedBy && fire.userDATA) {
      const i = props.gdict.LikedBy.indexOf(fire.userDATA.uid);
      return i;
    }
    return -1;
  };

  const setPriceAction = () => {
    if (!fire.isinternaluser()) return;
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: `Set Price`,
      html: (
        <Form className={"text-start p-1"}>
          <Form.Group className="mb-3" controlId="setPrice_price">
            <Form.Label>Price</Form.Label>
            <Form.Control autoFocus type="number" min={0} placeholder="Enter Price" defaultValue={props.gdict.Price} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="setPrice_cutwidth">
            <Form.Label>Cut Width</Form.Label>
            <Form.Control type="number" min={0} step={1} placeholder="Enter Cut Width" defaultValue={props.gdict.CutWidth ?? 56} />
          </Form.Group>
        </Form>
      ),
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: true,
      preConfirm: () => {
        const Price = Swal.getPopup().querySelector("#setPrice_price").value;
        const CutWidth = Swal.getPopup().querySelector("#setPrice_cutwidth").value;
        if (!Price || !CutWidth || isNaN(Price) || isNaN(CutWidth)) {
          Swal.showValidationMessage(`Please enter Price and CutWidth`);
        }
        return { Price, CutWidth };
      },
    }).then((res) => {
      if (res.isConfirmed) {
        queryClient.setQueryData(`fire_collectiondetail_DATA_${props.pdict._id}`, (data) => {
          let i = data.findIndex((d) => String(d.OrderBookDet_id) === String(props.dict.OrderBookDet_id));
          data[i].Price = res.value.Price;
          data[i].CutWidth = res.value.CutWidth;
          price_Mutation.mutate([{ id: props.gdict._id, Price: res.value.Price, CutWidth: res.value.CutWidth }]);
          return data;
        });
      }
    });
  };

  const iconClickAction = (Icon, error) => {
    if (overlayIconTimer.current) clearTimeout(overlayIconTimer.current);
    if (!Icon) {
      setoverlayIcon(<LoadingView variant={"light"} />);
    } else {
      setoverlayIcon(<Icon size={100} color={error ? "red" : "white"} className={`position-absolute top-0 bottom-0 d-flex w-100 h-100 animate__animated ${error ? `animate__flipInX` : `animate__bounceIn`} animate__faster`} style={{ zIndex: 100, padding: "160px" }} />);
      overlayIconTimer.current = setTimeout(() => setoverlayIcon(null), 2000);
    }
  };

  //#region like Methods
  const likeAction = (res) => {
    if (!fire.userDATA) {
      fire.signInClickAction(fire, null, "Sign in to get share your likes ❤️ or download the article images and more");
      return;
    }

    res && iconClickAction(AiFillHeart);

    queryClient.setQueryData(`fire_collectiondetail_DATA_${props.pdict._id}`, (data) => {
      let i = data.findIndex((d) => String(d.OrderBookDet_id) === String(props.dict.OrderBookDet_id));
      var p;
      if (res) {
        if (!data[i].LikedBy) data[i].LikedBy = [];
        data[i].LikedBy.push(fire.userDATA.uid);
        p = fetch_selectedImagepath();
      } else {
        let idx = get_likeByIndex();
        if (idx > -1) {
          data[i].LikedBy.splice(idx, 1);
        }
        p = Promise.resolve(null);
      }

      p.then((res) => {
        let d = {
          id: props.gdict._id,
          group_id: props.pdict._id,
          displayName: fire.userDATA.displayName,
          ImagePath: typeof selectedImageIndex === "number" && props.imagesarr[selectedImageIndex],
          Insert: res ? "Y" : "N",
          LikedBy: fire.userDATA.uid,
          OrderCode: props.dict.OrderCode,
          Remarks: props.pdict.Remarks,
          sharedWith: data[i].LikedBy,
        };
        like_Mutation.mutate(d);
      });

      return data;
    });
  };

  const ui_get_likeIcon = () => {
    if (props.gdict && !props.pdict.isStory) {
      let className = "icon cursor-pointer btn-push";
      var ele;
      if (get_likeByIndex() > -1) {
        ele = <AiFillHeart size={30} color={"red"} className={className} onClick={() => likeAction(false)} />;
      } else {
        ele = (
          <OverlayTrigger overlay={<Tooltip>Like Article</Tooltip>}>
            <div>
              <AiOutlineHeart size={30} className={className} onClick={() => likeAction(true)} />
            </div>
          </OverlayTrigger>
        );
      }
      return ele;
    }
    return null;
  };
  //#endregion

  //#region download Methods
  const downloadOBAction = () => {
    iconClickAction();
    fetch_selectedImagepath()
      .then((res) => {
        let json = {
          orderbookdet_ids: [props.dict.OrderBookDet_id],
          group_id: (props.pdict && props.pdict._id) ?? "",
          imagepath: res,
          IsInternalUser: fire.isinternaluser() ? "Y" : "N",
          IsPKJStock: props.pdict && props.pdict.isStockStory ? "Y" : "N",
        };
        downloadOB_Mutation.mutate({ json, filename: getFileNameString(props.dict.OrderCode), zip: false });
      })
      .catch((err) => {
        iconClickAction(BiError, true);
      });
  };

  const fetch_selectedImagepath = () => {
    if (typeof selectedImageIndex === "number" && props.imagesarr[selectedImageIndex]) {
      return downloadOBDetailImage(props.imagesarr[selectedImageIndex]).then((res) => res);
    } else return Promise.reject(false);
  };

  //#endregion

  return (
    <>
      <Card className={"shadow-sm h-100"}>
        <Card.Body className={"p-0"}>
          <div className={"d-flex justify-content-between p-2"}>
            <div>
              <Card.Title className={"mb-1 fw-bold"}>{props.isLoading ? <Skeleton className={"mb-1"} width={250} /> : (props.dict && props.dict.OrderCode) ?? (props.gdict && props.gdict.OrderCode)}</Card.Title>
              <Card.Subtitle className={"text-muted"}>{props.isLoading ? <Skeleton width={200} /> : props.dict && `${props.dict.WarpCount} x ${props.dict.WeftCount} / ${props.dict.Reed} x ${props.dict.Pick}`}</Card.Subtitle>
            </div>
            {props.gdict && !props.pdict.isStory ? (
              <div className={"text-center d-grid"}>
                {props.gdict.Price ? (
                  <SwatchCardPriceView onClick={setPriceAction} gdict={props.gdict} />
                ) : (
                  <>
                    {fire.isinternaluser() && (
                      <Button variant={"outline-primary"} size="sm" className={"rounded fw-bold my-auto"} onClick={setPriceAction}>
                        <small>Set Price</small>
                      </Button>
                    )}
                  </>
                )}
              </div>
            ) : (
              <>
                {props.pdict && props.pdict.isStockStory && props.dict && props.dict.ReceivedDate && (
                  <div className={"text-center d-grid"}>
                    <small className={"fw-bold d-block"}>
                      <Moment fromNow date={props.dict.ReceivedDate} />
                    </small>
                    <Badge>In Clearence</Badge>
                  </div>
                )}
              </>
            )}
          </div>
          <SwatchCardCarousel overlayIcon={overlayIcon} {...props} setselectedImageIndex={setselectedImageIndex} selectedImageIndex={selectedImageIndex} onClick={() => !props.fullscreen && !isMobile && setshowFullScreen(!showFullScreen)} />
          {!props.isLoading && (
            <div className={"p-2 lh-1"}>
              <div className={"d-flex justify-content-between"}>
                <Stack direction="horizontal" gap={2}>
                  {ui_get_likeIcon()}
                  <OverlayTrigger overlay={<Tooltip>Download image with fabric details as PDF</Tooltip>}>
                    <div>
                      <AiOutlineFileImage size={30} className={"icon cursor-pointer btn-push"} onClick={() => downloadOBAction()} />
                    </div>
                  </OverlayTrigger>
                  {props.images3darr && (
                    <OverlayTrigger
                      trigger="click"
                      rootClose={true}
                      overlay={
                        <Popover id="popover-contained" width={"500px"} style={{ width: "400px", height: "400px" }}>
                          <ThreeDView images3darr={props.images3darr} id={`ThreeDView_${props.dict.OrderBookDet_id}`} />
                          {/* <Link style={{ color: "inherit" }} to={"/threedobj"} state={{ ...props }} className={"position-absolute bottom-0 m-2"}>
                            <BsArrowsFullscreen size={15} />
                          </Link> */}
                        </Popover>
                      }
                    >
                      <div>
                        <OverlayTrigger overlay={<Tooltip>View 3D Garment Rendering</Tooltip>}>
                          <Button variant="link" className={"m-0 p-0"} style={{ color: "inherit", outline: "none" }}>
                            <IoShirtOutline size={30} className={"icon cursor-pointer btn-push"} />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger overlay={<Tooltip>Create new colorways in this pattern</Tooltip>}>
                    <Link to={"/cad"} state={{ ...props }} style={{ color: "inherit" }}>
                      <IoColorPaletteOutline size={30} className={"icon cursor-pointer btn-push"} />{" "}
                    </Link>
                    {/* <a className={"link-color-inherit"} href={`${fabrigram_oldurl}/cad${props.dict && `?Design_id=${props.dict.Design_id}`}`} target="_blank" rel="noreferrer">
                      <IoColorPaletteOutline size={30} className={"icon cursor-pointer btn-push"} />
                    </a> */}
                  </OverlayTrigger>
                </Stack>
                {(fire.isinternaluser() || (props.pdict && props.pdict.isStockStory)) && props.stocksdict && props.stocksdict.StockQty && (
                  <div className={"text-center d-grid"}>
                    <div>
                      <Card.Title className={"m-0 mb-1 fw-bold"}>{kFormatter(props.stocksdict.StockQty)}</Card.Title>
                      <Card.Subtitle className={"small"}>Stock Meters</Card.Subtitle>
                    </div>
                  </div>
                )}
              </div>
              <div>{ui_get_likedby()}</div>
              <div>{ui_get_fabricdetail}</div>
            </div>
          )}
        </Card.Body>
      </Card>
      <Modal show={showFullScreen} fullscreen onHide={() => setshowFullScreen(false)}>
        <Modal.Body>
          <FullScreenSwatchCard {...props} setselectedImageIndex={setselectedImageIndex} selectedImageIndex={selectedImageIndex} onClose={() => setshowFullScreen(false)} subtitle={ui_get_fabricdetail} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export function SwatchCardPriceView(props) {
  return (
    <div className={"text-center cursor-pointer"} onClick={props.onClick}>
      <small className={"fw-bold d-block"}>
        {props.gdict.Price}
        <small> @ {props.gdict.CutWidth}"CW</small>
      </small>
      <Badge>{props.gdict.Price.includes(".") ? "USD/yd" : "INR/mtr"}</Badge>
    </div>
  );
}
