import { useEffect } from "react";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { fetch_userDetails } from "../utility/fire_fetcher";

export function SharedWithContainer(props) {
  return (
    <ul className={props.small ? "m-0 p-0" : "m-0 px-0 py-2"}>
      {props.isLoading ? (
        <div className={`d-flex ${props.small ? `m-0` : `mt-3`}`}>
          <SharedWithView isLoading={true} />
          <SharedWithView isLoading={true} />
          <SharedWithView isLoading={true} />
        </div>
      ) : (
        <>{props.data && props.data.map((id, i) => <SharedWithView key={`SharedWithView_${id}`} small={props.small} id={id} i={i} />)}</>
      )}
    </ul>
  );
}

export function SharedWithView(props) {
  const data = useQuery(
    `SharedWithView_${props.id}`,
    () =>
      new Promise((resolve) => {
        fetch_userDetails(props.id, (res) => {
          resolve(res);
        });
      }),
    { enabled: typeof props.id === "string" }
  );

  if (props.isLoading) {
    return <Skeleton className={"m-1 p-1"} circle roundedCircle width={40} height={40} />;
  }

  return (
    <OverlayTrigger key={"bottom"} placement={"bottom"} overlay={<Tooltip id={`SharedWithView_tooltip_${props.id}`}>{data.data && data.data.displayName}</Tooltip>}>
      <li className={`list-group-item border-0 p-1 shadow-sm rounded-circle d-inline-block m-1 ${props.i === 0 ? `ms-0` : ``}`}>
        {data.isSuccess && data.data.photoURL ? <Image roundedCircle src={data.data.photoURL} alt="" width={props.small ? 26 : 35} height={props.small ? 26 : 35} /> : <FaUserCircle color={"lightgray"} size={props.small ? 26 : 35} />}
      </li>
    </OverlayTrigger>
  );
}
