import html2canvas from "html2canvas";
import { useRef } from "react";
import { Col, OverlayTrigger, Row, Tooltip, Button } from "react-bootstrap";
import { RiCloseCircleLine, RiDownloadCloud2Fill } from "react-icons/ri";
import { downloadURL, getFileNameString } from "../utility/helpers";
import { SwatchCardCarousel } from "./SwatchCardCarousel";
import { ThreeDView } from "./ThreeDView";

export default function FullScreenSwatchCard(props) {
  let ref1 = useRef();
  const downloadScreenAction = () => {
    html2canvas(ref1.current.parentElement, { useCORS: true, allowTaint: true, letterRendering: 1, backgroundColor: "black", scrollX: -window.scrollX, scrollY: 0, windowWidth: document.documentElement.offsetWidth, windowHeight: document.documentElement.offsetHeight }).then(function (canvas) {
      const image = canvas.toDataURL("image/jpeg");
      downloadURL(image, (props.dict && props.dict.OrderCode) + "_3d");
    });
  };

  return (
    <Row ref={ref1} className={"h-100 position-relative"}>
      <Col className={"position-relative d-flex flex-column"}>
        <div className={"position-absolute mx-4 my-3 text-light z-index-1000"}>
          <h5 className={"fw-bold m-0"}>{(props.dict && props.dict.OrderCode) ?? (props.gdict && props.gdict.OrderCode)}</h5>
          <h6>{props.dict && `${props.dict.WarpCount} x ${props.dict.WeftCount} / ${props.dict.Reed} x ${props.dict.Pick}`}</h6>
        </div>
        <SwatchCardCarousel fullscreen {...props} />
        {props.subtitle && (
          <div className={"mx-4 my-2 text-light"} style={{ zIndex: 1000 }}>
            {props.subtitle}
          </div>
        )}
      </Col>
      {props.images3darr && (
        <Col xs={4}>
          <ThreeDView id={`ThreeDView_${props.dict.OrderBookDet_id}`} images3darr={props.images3darr} variant={"light"} autoRotate />
        </Col>
      )}
      <Col data-html2canvas-ignore xs={1} className={"text-end d-flex flex-column"} style={{ width: "4%" }}>
        <div data-html2canvas-ignore className={"cursor-pointer"} onClick={props.onClose}>
          <RiCloseCircleLine size={30} color={"white"} />
        </div>
        <div className={"mb-3 mt-auto text-center"}>
          <OverlayTrigger placement="left" overlay={<Tooltip data-html2canvas-ignore>Download 3D Garment Rendering as an Image</Tooltip>}>
            <div onClick={downloadScreenAction}>
              <RiDownloadCloud2Fill className={"cursor-pointer"} size={25} color={"white"} />
            </div>
          </OverlayTrigger>
        </div>
      </Col>
    </Row>
  );
}
