export const permutationsn = (arr, n) => {
  var result = [];
  var indexes = [];
  for (var k = 0; k < n; k++) indexes.push(k);

  var i = 0;
  while (i < arr.length) {
    var items = [];
    var skip = false;

    for (var j = 0; j < indexes.length; j++) {
      var index = indexes[j];
      items.push(arr[index]);
    }

    var isWantTOCallAgain = false;
    do {
      var idxList = indexChange(indexes, arr, i);
      indexes = idxList["indexes"];
      i = idxList["i"];
      isWantTOCallAgain = idxList["isWantTOCallAgain"];
    } while (isWantTOCallAgain === true && i < arr.length);

    if (n === 1 && skip === false) {
      for (var k = 0; k < result.length; k++) {
        if (result[k][0] === items[0]) {
          skip = true;
          break;
        }
      }
    }
    if (!skip) result.push(items);
    if (result.length > 29999) return result;
  }

  return result;
};

function indexChange(indexes, arr, i) {
  var idx = indexes.length - 1;
  var lastIndex = indexes[idx];
  var isWantTOCallAgain = false;

  while (lastIndex === arr.length - 1 && idx > 0) {
    idx--;
    lastIndex = indexes[idx];
  }

  if (indexes[idx] < arr.length - 1) {
    indexes[idx]++;

    for (var k = idx + 1; k < indexes.length; k++) indexes[k] = 0;
    i = 0;
  }

  var chkArr = [];
  for (var kk = 0; kk < indexes.length; kk++) {
    if (chkArr.indexOf(indexes[kk]) >= 0) {
      isWantTOCallAgain = true;
      break;
    } else chkArr.push(indexes[kk]);
  }

  i++;
  return { indexes, i, isWantTOCallAgain };
}
