import { useMemo, useState } from "react";
import { Carousel, Spinner, Stack } from "react-bootstrap";
import { useQuery } from "react-query";
import { downloadOBDetailImage } from "../utility/fire_fetcher";
import LazyImage from "./LazyImage";
import { SwatchCardPriceView } from "./SwatchCard";

export function SwatchCardCarousel(props) {
  const ui_get_tagsbadge = useMemo(() => {
    let d = props.tagsdict && props.tagsdict.TagsName;
    var arr = [];
    if (d) {
      const ui_push_img = (alt, src) => {
        let img = <img className={"shadow-sm rounded-bottom"} alt={alt} width={38} height={70} src={`images/${src}`} />;
        arr.push(img);
      };

      d = d.toLowerCase();
      if (d.includes("sustain")) ui_push_img("tag-sustainable", "tag-sustainable@2x.jpg");
      if (d.includes("bottom") || d.includes("short")) ui_push_img("tag-bottom", "tag-bottom@2x.jpg");
      if (d.includes("female") || d.includes("women") || d.includes("voile") || d.includes("girl")) ui_push_img("tag-women", "tag-women@2x.jpg");
      else if (d.includes("men") || d.includes("male") || d.includes("boy")) ui_push_img("tag-men", "tag-men@2x.jpg");
    }
    return (
      <Stack direction={"horizontal"} gap={2} className={`end-0 top-0 position-absolute w-auto h-auto me-2 z-index-900`}>
        {props.fullscreen && props.gdict && props.gdict.Price && (
          <div style={{ height: "70px" }} className={"fw-bold mt-0 me-3 rounded-bottom p-2 shadow-lg bg-white my-auto"}>
            <SwatchCardPriceView gdict={props.gdict} />
          </div>
        )}
        {arr}
      </Stack>
    );
  }, [props.tagsdict, props.fullscreen, props.gdict]);

  const style = useMemo(() => {
    let s = { height: props.height ?? (props.fullscreen ? "100%" : "500px") };
    if (props.fullscreen) s.borderRadius = "30px";
    return s;
  }, [props.fullscreen, props.height]);

  return (
    <div className={`position-relative ${props.fullscreen && `overflow-hidden flex-grow-1`} ${props.className}`} style={style}>
      {props.imagesarr && props.imagesarr.length > 0 && props.dict ? (
        <Carousel style={{ height: "inherit" }} fade interval={null} onSelect={(i) => props.setselectedImageIndex && props.setselectedImageIndex(i)} defaultActiveIndex={props.selectedImageIndex}>
          {props.imagesarr.map &&
            props.imagesarr.map((d, i) => {
              const id = `SwatchCardCarouselItem_${props.dict.OrderBookDet_id}_${d}`;
              return (
                <Carousel.Item key={id} onClick={props.onClick} style={{ height: "inherit" }}>
                  <SwatchCardCarouselItem key={id} id={id} style={style} imagepath={d} />
                </Carousel.Item>
              );
            })}
        </Carousel>
      ) : null}
      {props.overlayIcon}
      {ui_get_tagsbadge}
    </div>
  );
}

function SwatchCardCarouselItem(props) {
  let imagepath1 = useQuery(`imagepath1_fetch_${props.id}`, () => downloadOBDetailImage(props.imagepath), { enabled: typeof props.imagepath === "string" && typeof props.id === "string" });
  return <LazyImage src={imagepath1.data} height={props.style.height} />;
}
