import Skeleton from "react-loading-skeleton";

export default function SkeletonTitleView(props) {
  return (
    <div className={"mt-3"}>
      <Skeleton height={50} width={300} />
      <br />
      <Skeleton className={"mt-1"} width={150} />
      {props.subtitle && (
        <>
          <br /> <br /> <br />
          <Skeleton className={"mt-3"} width={"100%"} />
          <Skeleton width={"80%"} />
        </>
      )}
    </div>
  );
}
