import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { CloseButton, Stack, Button, Modal, Container, Dropdown, DropdownButton } from "react-bootstrap";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { CadContext, CadProvider } from "../CadContext";
import { ColorPaletteView, ColorPaletteView2 } from "../components/ColorPaletteView";
import { FirebaseContext } from "../components/FirebaseContext";
import LoadingView from "../components/LoadingView";
import SearchInputView from "../components/SearchInputView";
import { SwatchCardCarousel } from "../components/SwatchCardCarousel";
import { Sql_CollectionImages_DATA } from "../components/SwatchCardContainer";
import { fetchDesignDetails, fetchOrdersSearch } from "../utility/fetcher";
import { fabrigram_oldurl, getIDs2, shuffle, swalToastProps } from "../utility/helpers";
import { permutationsn } from "../utility/permutation";
import { ColorBook, get_colorbook_selectedDATA } from "./ColorBook";
import IndexPage from "./IndexPage";

export default function CadPage(props) {
  const location = useLocation();
  const [searchStr, setsearchStr] = useState((location.state && location.state.dict && location.state.dict.OrderCode) ?? null);
  const ob_DATA = useQuery(["ob_DATA", searchStr], () => fetchOrdersSearch(searchStr), { enabled: typeof searchStr === "string" });

  const enabled = ob_DATA.isLoading === false && typeof ob_DATA.data === "object" && typeof ob_DATA.data.Design_id === "number";
  const image_DATA = Sql_CollectionImages_DATA(ob_DATA.data && ob_DATA.data.OrderBookDet_id, enabled, 1);

  const designDetail_DATA = useQuery(
    `designDetail_DATA_${ob_DATA.data && ob_DATA.data.Design_id}`,
    () => {
      return fetchDesignDetails(ob_DATA.data.Design_id);
    },
    { enabled }
  );
  const [opencolorbook, setopencolorbook] = useState(false);
  const [selectedcolorbook, setselectedcolorbook] = useState([]);
  const [permutations, setpermutations] = useState();
  const [showleftpanel, setshowleftpanel] = useState(true);
  const fire = useContext(FirebaseContext);

  const imagesarr = useMemo(() => {
    if (image_DATA.data) {
      let d = image_DATA.data[0];
      let keys = Object.keys(d);
      if (keys.length) {
        let arr = d[keys[0]];
        if (arr.length) return arr;
      }
    }
    return (ob_DATA.data && ob_DATA.data.ImagePath && [ob_DATA.data.ImagePath]) ?? null;
  }, [image_DATA.data, ob_DATA.data]);

  const generateAction = useCallback(() => {
    if (designDetail_DATA.data && designDetail_DATA.data.Detail) {
      let colors = getIDs2(designDetail_DATA.data.Detail, "ColorName", true);

      if (!selectedcolorbook || selectedcolorbook.length < colors.length) {
        let d = { ...swalToastProps, ...{ title: `Hi ${fire.userDATA.displayName ?? fire.userDATA.email}`, text: `Color Palette doesn't have the minimum no of colors to draw this pattern. (Min ${colors.length} colors needed)`, icon: "warning" } };
        Swal.fire(d);
      } else {
        let res = permutationsn(selectedcolorbook, colors.length);
        res = shuffle(res);
        if (location.state && location.state.cdict && location.state.cdict.colors) res[0] = location.state.cdict.colors;
        setpermutations(res);
        setshowleftpanel(false);
      }
    } else if (ob_DATA.isLoading || designDetail_DATA.isLoading) {
      let d = { ...swalToastProps, ...{ title: `Hi There`, text: `Mill Code is ${searchStr} is incorrect`, icon: "warning" } };
      Swal.fire(d);
    } else {
      let d = { ...swalToastProps, ...{ title: `Hi There`, text: `Mill Code is ${searchStr} is incorrect`, icon: "error" } };
      Swal.fire(d);
    }
  }, [designDetail_DATA.data, designDetail_DATA.isLoading, fire, location.state, ob_DATA.isLoading, searchStr, selectedcolorbook]);

  const allchars = useMemo(() => ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"], []);

  useEffect(() => {
    if (!selectedcolorbook || selectedcolorbook.length === 0) {
      if (location.state && location.state.cdict && location.state.cdict.colors) {
        setselectedcolorbook(location.state.cdict.colors);
      } else {
        let d = get_colorbook_selectedDATA();
        setselectedcolorbook(d);
      }
    }
  }, [generateAction]);

  const caddata = useMemo(() => {
    let d = designDetail_DATA.data;
    if (d && ob_DATA.data) {
      let detail = d.Detail;
      if (Array.isArray(detail)) {
        var warp = [],
          weft = [],
          chars = {},
          charindex = 0;
        detail.forEach((t, i) => {
          let _char = chars[t.Color_ids];
          if (!_char) {
            let char = allchars[charindex];
            charindex++;
            chars[t.Color_ids] = char;
            _char = char;
          }
          let r = `${_char}${t.Ends}`;
          if (t.WarpWeftFlag === "WARP") {
            warp.push(r);
            if (t.InnerRepeat) warp.push(`X${t.InnerRepeat}`);
          } else {
            weft.push(r);
            if (t.InnerRepeat) weft.push(`X${t.InnerRepeat}`);
          }
        });

        var patternDATA = [];
        let max = Math.max(warp.length, weft.length);
        for (var i = 0; i < max; i++) {
          var a = ["", ""];
          if (warp.length > i) a[0] = warp[i];
          if (weft.length > i) a[1] = weft[i];
          patternDATA.push(a);
        }

        let cadconfig = {
          reed: d.Reed,
          pick: d.Pick,
          stylename: ob_DATA.data.OrderCode,
          filename: ob_DATA.data.OrderCode,
        };

        let weaveDATA;

        if (d.PegPlan) {
          let pegplan = JSON.parse(d.PegPlan);
          // pegplan = pegplan.map((p) => p.reverse());
          let pegrepeat = d.PegRepeat ? JSON.parse(d.PegRepeat) : [];
          if (pegrepeat.length === 0) {
            pegrepeat = Array(0).fill(pegplan.length);
            pegrepeat[pegrepeat.length - 1] = 1;
          }
          pegrepeat = pegrepeat.reverse();

          let dent = d.Dent ? JSON.parse(d.Dent) : [];
          if (Array.isArray(dent)) {
            if (dent.length && Array.isArray(dent[0])) dent = dent[0];
          }
          if (dent.length === 0) {
            dent = Array.from(Array(pegplan[0].length + 1).keys());
            dent.shift();
          }

          let dentrepeat = [];
          if (d.DentRepeat) {
            if (typeof d.DentRepeat === "string") {
              if (d.DentRepeat.charAt(d.DentRepeat.length - 1) === ",") d.DentRepeat = d.DentRepeat.slice(0, -1);
              dentrepeat = JSON.parse(d.DentRepeat);
            }
          }

          if (dentrepeat.length === 0) {
            dentrepeat = Array(dent.length).fill(0);
            dentrepeat[dentrepeat.length - 1] = 1;
          }

          let name = getERPWeaveType_id_reverse(d.WeaveType_id);
          weaveDATA = {
            pegplan,
            pegrepeat,
            dent,
            dentrepeat,
            name,
          };
        } else {
          weaveDATA = {
            pegplan: [
              [1, 0, 1, 0],
              [0, 1, 0, 1],
              [1, 0, 1, 0],
              [0, 1, 0, 1],
            ],
            dent: [1, 2, 3, 4],
            dentrepeat: [0, 0, 0, 1],
            name: "Plain",
          };
        }

        let json = { data: { patternDATA, weaveDATA, cadconfig, selected_colorwayIndex: 0 } };
        return json;
      }
    }
    return null;
  }, [allchars, designDetail_DATA.data]);

  const onSwitchColorAction = useCallback(
    (index, k, i) => {
      let kd = permutations[index][k];
      let id = permutations[index][i];

      setpermutations((p) => {
        p[index][i] = kd;
        p[index][k] = id;
        return [...p];
      });
    },
    [permutations]
  );

  const onReplaceColorAction = useCallback((index, k) => {
    setopencolorbook({
      index,
      k,
    });
  }, []);

  const ui_colorwayview = useMemo(() => <CADColorwayView key={"CADColorwayView1"} permutations={permutations} onSwitchColorAction={onSwitchColorAction} onReplaceColorAction={onReplaceColorAction} />, [permutations, onSwitchColorAction, onReplaceColorAction]);

  let access = useMemo(() => fire.isdomainuser(), [fire]);

  if (!fire.userDATA) {
    return <IndexPage />;
  } else if (fire.userDATA && !access) {
    return <IndexPage />;
  }

  return (
    <div className={"vh-100"}>
      {caddata ? (
        <CadProvider onOpen={caddata}>{!showleftpanel && ui_colorwayview}</CadProvider>
      ) : (
        <>
          <CADTutorialView />
        </>
      )}
      <Modal show={opencolorbook} onHide={() => setopencolorbook(false)} size={"xl"}>
        <Modal.Header closeButton>
          <Modal.Title>Color Book</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: 200 }}>
          <ColorBook
            modal
            doneAction={(arr) => {
              if (typeof opencolorbook === "object") {
                console.log(arr);
                setpermutations((p) => {
                  p[opencolorbook.index][opencolorbook.k] = arr[0];
                  return [...p];
                });
              } else setselectedcolorbook(arr);
              setopencolorbook(false);
            }}
            single={typeof opencolorbook === "object"}
          />
        </Modal.Body>
      </Modal>
      <div
        style={{ left: showleftpanel ? 0 : -300, transition: "left 0.2s ease-in-out", width: "320px" }}
        className={`position-absolute top-50 translate-middle-y bg-body p-4 ms-4 rounded-3 shadow`}
        onClick={(e) => {
          if (!showleftpanel) setshowleftpanel(true);
          e.preventDefault();
        }}
      >
        <Stack gap={2}>
          <CloseButton onClick={() => setshowleftpanel(false)} />
          <div className="pt-2">
            <h6>Mill Code</h6>
            <SearchInputView
              defaultValue={ob_DATA.data && ob_DATA.data.OrderCode}
              onBlur={(e) => {
                let value = e.target.value.trim();
                setsearchStr((v) => value);
              }}
            />
            <SwatchCardCarousel height={"200px"} fullscreen dict={ob_DATA.data} imagesarr={imagesarr} className={"mt-2"} />
          </div>
          <hr />
          <div>
            <Stack direction="horizontal">
              <h6 className="mb-0">Color Palette</h6>
              <Button className="ms-auto" variant={"outline-dark"} size={"sm"} onClick={() => setopencolorbook(true)}>
                Pick Colors
              </Button>
            </Stack>
            <ColorPaletteView type={2} colors={selectedcolorbook} className={"mt-2"} />
          </div>
          <Button className="w-100 mt-4" variant={"outline-dark"} size={"sm"} onClick={generateAction}>
            Generate
          </Button>
        </Stack>
      </div>
    </div>
  );
}

function CADColorwayView(props) {
  let cad = useContext(CadContext);
  const [selectedindex, setselectedindex] = useState(0);
  let nextRef = useRef();
  let prevRef = useRef();
  const allchars = useMemo(() => ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"], []);

  const ui_comboname = useMemo(() => {
    if (Array.isArray(props.permutations) && typeof selectedindex === "number") {
      const d = props.permutations[selectedindex];
      let name = d[0].name ? d[0].name : d[0].code;
      if (name.length === 2) {
        let name1 = d[1].name ? d[1].name : d[1].code;
        name = name + "/" + name1;
      }
      return name;
    }
    return null;
  }, [props.permutations, selectedindex]);

  const ui_selectedpermutation = useMemo(() => {
    if (Array.isArray(props.permutations) && typeof selectedindex === "number") {
      let data = props.permutations[selectedindex];
      let dropdownItems = [];
      data.forEach((d, k) => {
        let items = [];
        items.push(<Dropdown.Item onClick={() => props.onReplaceColorAction && props.onReplaceColorAction(selectedindex, k)}>Replace Color</Dropdown.Item>);
        data.forEach((d, i) => i !== k && items.push(<Dropdown.Item onClick={() => props.onSwitchColorAction && props.onSwitchColorAction(selectedindex, k, i)}>Switch to Color {i + 1}</Dropdown.Item>));
        dropdownItems.push(items);
      });

      return (
        <ColorPaletteView2
          style={{ display: "block" }}
          data={data}
          header={
            <Stack direction="horizontal" gap={2} className="mx-2 mt-2">
              <Button ref={prevRef} variant="outline-dark" size="sm" onClick={() => setselectedindex(Math.max(0, selectedindex - 1))}>
                Previous
              </Button>
              <Button ref={nextRef} variant="outline-dark" size="sm" onClick={() => setselectedindex(Math.min(props.permutations.length - 1, selectedindex + 1))}>
                Next
              </Button>

              <DropdownButton variant="outline-dark" title="Download CAD" size="sm" disabled={!cad.colorwaysDATA || cad.colorwaysDATA.length === 0}>
                <Dropdown.Item onClick={() => cad.printAction(false, 8.27, 11.26, true, undefined, undefined, undefined, 0.12, false, cad, true, false)}>A4 Size</Dropdown.Item>
                <Dropdown.Item onClick={() => cad.printAction(false, 8.27 * 2, 11.26 * 2, true, undefined, undefined, undefined, 0.12, false, cad, true, false)}>A3 Size</Dropdown.Item>
                <Dropdown.Item onClick={() => cad.printAction(false, 8.27 * 3, 11.26 * 3, true, undefined, undefined, undefined, 0.12, false, cad, true, false)}>A2 Size</Dropdown.Item>
              </DropdownButton>
              <small className="ms-auto d-flex flex-column text-center lh-1" style={{ width: "140px" }}>
                <div className="fw-bold">{ui_comboname}</div>
                <small>
                  Combo {selectedindex + 1} of {props.permutations.length}
                </small>
              </small>
            </Stack>
          }
          dropdownItems={dropdownItems}
        />
      );
    }
    return null;
  }, [props.permutations, selectedindex, ui_comboname, cad]);

  useEffect(() => {
    if (typeof selectedindex === "number" && props.permutations) {
      const d = props.permutations[selectedindex];
      let colorwaysDATA = [
        {
          DATA: d.map((_d, i) => {
            return { color: `#${_d.hex}`, name: _d.code, char: allchars[i] };
          }),
          headDATA: {
            title: ui_comboname,
            id: "123",
          },
        },
      ];
      cad.setcolorwaysDATA([...colorwaysDATA]);
    }
  }, [props.permutations, selectedindex, ui_comboname]);

  useEffect(() => {
    const documentKeyDownAction = (e) => {
      if (!props.permutations || !props.permutations.length) return;
      switch (e.key) {
        case "ArrowLeft":
          setselectedindex(Math.max(0, selectedindex - 1));
          prevRef.current && prevRef.current.classList.add("bg-dark", "text-white", "no-transition");
          break;
        case "ArrowRight":
          setselectedindex(Math.min(props.permutations.length - 1, selectedindex + 1));
          nextRef.current && nextRef.current.classList.add("bg-dark", "text-white", "no-transition");
          break;
        default:
          break;
      }
    };

    const documentKeyUpAction = (e) => {
      if (!prevRef.current || !nextRef.current) return;
      switch (e.key) {
        case "ArrowLeft":
          prevRef.current && prevRef.current.classList.remove("bg-dark", "text-white", "no-transition");
          break;
        case "ArrowRight":
          nextRef.current && nextRef.current.classList.remove("bg-dark", "text-white", "no-transition");
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", documentKeyDownAction);
    document.addEventListener("keyup", documentKeyUpAction);

    return () => {
      document.removeEventListener("keydown", documentKeyDownAction);
      document.removeEventListener("keyup", documentKeyUpAction);
    };
  }, [prevRef, nextRef, selectedindex, props.permutations]);

  return ui_selectedpermutation;
}

function getERPWeaveType_id_reverse(value) {
  switch (value) {
    case 1:
      return "Plain";
    case 41:
      return "2/2RHT";
    case 42:
      return "2/2LHT";
    case 21:
      return "2/1RHT";
    case 16:
      return "2/1LHT";
    case 22:
      return "3/1RHT";
    case 17:
      return "3/1LHT";
    case 2:
      return "Oxford";
    case 48:
      return "4x4HBT";
    case 49:
      return "8x8HBT";
    case 50:
      return "12x12HBT";
    case 51:
      return "16x16HBT";
    case 18:
      return "4/1SATIN";
    case 36:
      return "2/2MATT";
    case 38:
      return "Crape";
    default:
      return "Dobby";
  }
}

function CADTutorialView(props) {
  return (
    <div id="tutorial-container" className="position-absolute top-50 end-0 translate-middle-y d-flex justify-content-end  me-5 py-0 align-items-center">
      <div className="d-flex flex-column" style={{ width: "560px" }}>
        <section className="d-flex flex-column p-2 m-2">
          <div className="display-4">Fabrigram CAD's</div>
          <div className="text-secondary">
            Create New Colorway Artworks in any of our fabric articles in just
            <br />
            <b>3 steps</b>
          </div>
        </section>
        <div id="tutorial-1" className="d-flex rounded shadow-sm p-2 m-2">
          <h5>
            Enter ALC Fabric Code
            <br />
            <small className="text-secondary">Each Fabrics articles of ours are uniquely identified with a fabric code which starts with "OB".</small>
          </h5>
        </div>
        <div id="tutorial-2" className="d-flex rounded shadow-sm p-2 m-2">
          <h5>
            Pick your PANTONE® TCX or Archroma® Colors
            <br />
            <small className="text-secondary">Pick your colors based on your mood/theme from our extensive PANTONE® TCX or Archroma® color library</small>
          </h5>
        </div>
        <div id="tutorial-3" className="d-flex rounded shadow-sm p-2 m-2">
          <h5>
            Click Generate
            <br />
            <small className="text-secondary">Fabrigram create all the possible permutations & combinations fabric article & your colors</small>
          </h5>
        </div>
        <section id="whatif-label" className="d-flex flex-column p-2">
          <small className="text-secondary">
            <u>What if you would like to create CAD's for your new pattern ?</u>
          </small>
        </section>
        <section id="whatif-label-2" className="d-flex flex-column mx-2 rounded">
          <small className="text-secondary">
            To create new patterns, you would need to share your artwork with ALC over email. ALC will add your artwork pattern into Fabrigram and assign a Mill Code which will be shared back to you for using in our app. Your Artwork will not be shared with any other buyer/brand.
          </small>
        </section>
      </div>
    </div>
  );
}
