import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import TitleView from "../components/TitleView";

export function CostingPage(props) {
  return (
    <Container className={"mt-5 pt-5"}>
      <TitleView title={"Costing"} />
      <Form>
        <Row md={3} xs={1}>
          <Col children={<TextFieldView id={"Reed"} />} />
          <Col children={<TextFieldView id={"Pick"} />} />
          <Col children={<TextFieldView id={"Finished Width"} />} />
        </Row>
      </Form>
    </Container>
  );
}

function TextFieldView(props) {
  return (
    <Form.Group>
      <FloatingLabel controlId={props.id} label={props.id}>
        <Form.Control size={"sm"} name={props.id} type="text" placeholder={props.id} />
      </FloatingLabel>
    </Form.Group>
  );
}
