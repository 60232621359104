import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { CadProvider } from "../CadContext";
import "../css/welcomepage.css";
export default function IndexPage() {
  const appscreen_crawl_onLoad = () => {
    let body_con = document.getElementById("body-container");
    body_con.classList.add("opacity-1");
    body_con.classList.remove("overflow-hidden");

    setsrcImage("appscreen-main");
    setsrcImage("appscreen-2");
    setsrcImage("appscreen-3");

    setsrcImage("appscreen-stories");

    setsrcImage("appscreen-collection1");
    setsrcImage("appscreen-collection2");
    setsrcImage("appscreen-palette1");
    setsrcImage("appscreen-palette2");
    setsrcImage("appscreen-cad");
    setsrcImage("appscreen-3d");
    setsrcImage("appscreen-scan");
    setsrcImage("appscreen-3d");
    setsrcImage("appscreen-search");
    setsrcImage("appscreen-dark");
  };

  const setsrcImage = (id) => {
    let img = document.getElementById(id);
    if (img != null) {
      img.src = "images/appscreens/" + id + ".jpg";
      img.setAttribute("loading", "auto");
    }
  };

  return (
    <Container fluid className={"mt-3 mx-0 p-0"}>
      {/* <CadProvider /> */}
      <div id="body-container" className=" overflow-hidden">
        <div className="p-5"></div>
        <div className="container-fluid d-flex flex-column justify-content-center align-items-center px-0">
          <img loading="eager" src="images/FabrigramIcon.png" width="120" height="120" />
          <h5>Fabrigram by ALC</h5>
          <br />
          <div className="text-center m-4">
            <h5 className="display-4 fw-bold">
              A Digital Fabric Library
              <br />
              from the Future.
            </h5>
            <a href="https://apps.apple.com/us/app/id1509616638" target="_blank">
              <img className="my-3 mx-1 zoom-shadow" src="/images/appscreens/itunes-app-store-logo-white.png" width="150" />
            </a>
            <Link to="/index">
              <img className="my-3 mx-1 zoom-shadow" src="/images/appscreens/web_app_icon.png" width="150" />
            </Link>
            <br />
            <small style={{ fontSize: "10px" }} className="text-center text-secondary">
              Fabrigram is free to use for all Apparel Brands. Prior to use, Fabrigram Team would need to grant access to the brand/user to login and view all of its contents.
              <br />
              <u>
                <Link className="text-secondary" to="/signup">
                  Find out more about how to get access
                </Link>
              </u>
            </small>
          </div>
          <div className="app-screen">
            <div className="fade1 fade1-top"></div>
            <div className="crawl">
              <img loading="eager" id="appscreen-crawl" onLoad={appscreen_crawl_onLoad} width="100%" src="images/appscreens/appscreen-11.jpg" />
            </div>
            <div className="fade1 fade1-bottom"></div>
          </div>
        </div>
        <div className="container p-sm-5 min-vh-100 d-flex flex-column align-content-center justify-content-center">
          <h1 className="display-4 fw-bold py-10 ">Future is Now ! 🚀</h1>
          <h1 className="display-4 fw-bold">
            <a className="hue-text">Fabrigram</a> is a user friendly Digital Fabric Library made by the people who know all about making woven fabrics in-N-out & back-again.
          </h1>
        </div>
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">Exclusively, Free to Use</h1>
          <h3 className="fw-normal text-secondary">Free to use for all Woven Apparel Brands.</h3>
        </div>
        <img id="appscreen-main" loading="lazy" width="100%" />
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">Fabric on Focus</h1>
          <h3 className="fw-normal text-secondary">Every fabric in the library is presented with all required technical spec details in a brand's perspective. Fabric Type , Weave , Weight , Finish , Stock Availability and more</h3>
        </div>
        <img id="appscreen-2" loading="lazy" className="py-5" width="100%" />
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">Picture Perfect</h1>
          <h3 className="fw-normal text-secondary">Each Fabric in the library is uploaded with product images taken by our in-house DSLR photographer. Also, after wash garment mock images are also uploaded specifically for Indigos & Sulphurs to visulaize the hi-low effect</h3>
        </div>
        <img id="appscreen-3" loading="lazy" className="py-5" width="100%" />
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">Stories</h1>
          <h3 className="fw-normal text-secondary">
            Look out for Weekly Stories from the Mill with Innovative Product Developments , Seasonal Collections , New Fashion Trends & much more.
            <br />
            <u>
              <Link className="text-secondary" to="/stories">
                Check out our recent Stories
              </Link>
            </u>
          </h3>
        </div>
        <img id="appscreen-stories" loading="lazy" className="py-5" width="100%" />
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">Create Collections & Share</h1>
          <h3 className="fw-normal text-secondary">You can create your own collections of the fabric articles you ❤️ like and share the collection across your Team or the Mill for them to have a look. Mill can arrange physical hangers accordingly.</h3>
        </div>
        <img id="appscreen-collection1" loading="lazy" className="" width="100%" />
        <img id="appscreen-collection2" loading="lazy" className="" width="100%" />
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">PANTONE® Color Palette</h1>
          <h3 className="fw-normal text-secondary">Create Your Palette. Your Theme. Your Creativity. With the best and the most renowned color space in textile.PANTONE®</h3>
        </div>
        <img id="appscreen-palette1" loading="lazy" className="py-5" width="100%" />
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">World is a colorful playground</h1>
          <h3 className="fw-normal text-secondary">Well, yes. You could pick a color by using your device camera and let fabrigram identifty the closest matching PANTONE® TCX for you to add it to your palette</h3>
        </div>
        <img id="appscreen-palette2" loading="lazy" className="py-5" width="100%" />
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">New Fabric Colorways with Just a Swipe</h1>
          <h3 className="fw-normal text-secondary">
            Apply your color palette to any of fabric articles in the library and let fabrigram do the magic for you in seconds. Fabrigram render all the possible CAD's permutated with the color combinations with your color palette for any fabric in the library.
            <br />
            <br />
            You can add the liked colorways to your collections and communicate with the Mill for them to make Handloom/SSY and submit
          </h3>
        </div>
        <img id="appscreen-cad" loading="lazy" className="py-5" width="100%" />
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">
            3D Shirt Rendering
            <br />
            That too, with just a swipe !
          </h1>
          <h3 className="fw-normal text-secondary">
            Apply your color palette to any of fabric articles in the library and let fabrigram do the magic for you in seconds. Fabrigram render all the possible CAD's permutated with the color combinations with your color palette for any fabric in the library.
          </h3>
        </div>
        <img id="appscreen-3d" loading="lazy" className="py-5" width="100%" />
        {/* <!--    <div className="container p-sm-5">-->
  <!--        <h1 className="display-4 fw-bold">3D Garments Marketplace</h1>-->
  <!--        <h3 className="fw-normal text-secondary">Apply your color palette to any of fabric articles in the library and let fabrigram do the magic for you in seconds. Fabrigram render all the possible CAD's permutated with the color combinations with your color palette for any fabric in the library.</h3>-->
  <!--    </div>-->
  <!--    <img id="appscreen-3d"  loading="lazy" className="py-5"  width="100%">--> */}
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">Scan Articles</h1>
          <h3 className="fw-normal text-secondary">Scan the QR Code of any our physical fabric hangers and do any of the above.</h3>
        </div>
        <img id="appscreen-scan" loading="lazy" className="py-5" width="100%" />
        <div className="container p-sm-5">
          <h1 className="display-4 fw-bold">Advanced Search</h1>
          <h3 className="fw-normal text-secondary">Deep Dive in more with technical textile related search terms and search for the right fabric you are looking for.</h3>
        </div>
        <img id="appscreen-search" loading="lazy" className="py-5" width="100%" />
        <div className="w-100 bg-black">
          <div className="container p-sm-5">
            <h1 className="display-4 fw-bold text-white">Dark Mode</h1>
            <h3 className="fw-normal text-white">Looks Great in Dark Mode Too </h3>
          </div>
          <img id="appscreen-dark" loading="lazy" className="py-5" width="100%" />
        </div>
      </div>
    </Container>
  );
}
