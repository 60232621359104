import { Card, Col, Container, OverlayTrigger, Popover, Row, Stack, Tooltip } from "react-bootstrap";
import { FcEditImage } from "react-icons/fc";
import { RiImageEditFill } from "react-icons/ri";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { fire_downloadImage } from "../utility/fire_fetcher";
import { downloadURL, fabrigram_oldurl } from "../utility/helpers";
import { ColorCard } from "./ColorCard";
import { ColorPaletteView } from "./ColorPaletteView";

export function ComboSwatchCardContainer(props) {
  return (
    <Container fluid className={"w-100 my-4 lead bg-light p-4 rounded shadow"}>
      <Row>
        <Col>
          <div className={"lead"}>Colorways of {props.dict.OrderCode}</div>
        </Col>
      </Row>
      <Row md={5}>
        {props.data.map((d, i) => (
          <ComboSwatchCard dict={props.dict} cdict={d} i={i + 1} t={props.data.length} />
        ))}
      </Row>
    </Container>
  );
}

function ComboSwatchCard(props) {
  let ThumbnailPath = useQuery(`ComboSwatchCard_thumbnailpath_${props.cdict.ThumbnailPath}`, () => fire_downloadImage(null, props.cdict.ThumbnailPath), { enabled: typeof props.cdict.ThumbnailPath === "string" });
  return (
    <Col className={"mt-4"}>
      <Card className={"d-wrapper"}>
        <div className={"position-relative m-2 overflow-hidden rounded"} style={{ height: 220 }}>
          <Link to={"/cad"} state={{ ...props }} style={{ color: "inherit" }} className={"link-color-inherit overlay-hover position-absolute top-0 left-0 w-100 h-100 text-center d-flex justify-content-center align-items-center"}>
            <div>
              <RiImageEditFill size={35} />
              <small style={{ fontSize: "16px" }} className={"d-block"}>
                download/edit & more
              </small>
            </div>
          </Link>
          {/* <a
            className={"link-color-inherit overlay-hover position-absolute top-0 left-0 w-100 h-100 text-center d-flex justify-content-center align-items-center"}
            href={`${fabrigram_oldurl}/cad${props.cdict && `?Design_id=${props.dict.Design_id}&groupdetail_id=${props.cdict._id}&combo_id=${props.cdict.__id}`}`}
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <RiImageEditFill size={35} />
              <small style={{ fontSize: "16px" }} className={"d-block"}>
                download/edit & more
              </small>
            </div>
          </a> */}
          <img alt={""} height={220} className={"object-fit-cover w-100"} src={ThumbnailPath.data ?? null} />
        </div>
        <Card.Body className={"text-center pt-1"}>
          <ColorPaletteView colors={props.cdict.colors} />
          <div className={"d-block mt-3 lh-1 lead"}>{props.cdict.colorwayName}</div>
          <small style={{ fontSize: "14px" }} className={"d-block text-secondary fw-bold"}>
            {props.i} of {props.t}
          </small>
        </Card.Body>
      </Card>
    </Col>
  );
}
