import { Stack } from "react-bootstrap";
import { FcDeployment, FcEditImage, FcHome, FcStackOfPhotos } from "react-icons/fc";
import { fabrigram_oldurl } from "../utility/helpers";
import { NavLink } from "react-router-dom";
import { useContext, useMemo } from "react";
import { FirebaseContext } from "./FirebaseContext";

export default function MenuView(props) {
  const fire = useContext(FirebaseContext);

  let access = useMemo(() => fire.isdomainuser(), [fire]);

  const onLogoClickAction = (e, v) => {
    if (v && access === false) {
      e.preventDefault();
    } else {
      props.onLogoClickAction && props.onLogoClickAction();
    }
  };

  return (
    <Stack direction={props.direction ?? "vertical"} gap={props.gap ?? 2} className={`text-center m-4 cursor-pointer ${props.className}`}>
      <Stack>
        <NavLink activeClassName={`text-primary`} className={`link-color-inherit  ${!access && "grayscale opacity-50"}`} to="/home" disabled={!access} onClick={(e) => onLogoClickAction(e, true)}>
          <FcHome size={90} />
          <p className={"lead text-center"}>Home</p>
        </NavLink>
      </Stack>
      <Stack>
        <NavLink activeClassName={"text-primary"} className={"link-color-inherit"} to="/stories" onClick={() => onLogoClickAction(false)}>
          <FcStackOfPhotos size={90} />
          <p className={"lead text-center"}>Stories</p>
        </NavLink>
      </Stack>
      <Stack>
        <NavLink activeClassName={"text-primary"} className={"link-color-inherit"} to="/stocks" onClick={() => onLogoClickAction(false)}>
          <FcDeployment size={90} />
          <p className={"lead text-center"}>Stocks</p>
        </NavLink>
      </Stack>
      <Stack>
        <NavLink activeClassName={"text-primary"} className={`link-color-inherit  ${!access && "grayscale opacity-50"}`} to="/cad" disabled={!access} onClick={() => onLogoClickAction(false)}>
          <FcEditImage size={90} />
          <p className={"lead text-center"}>CAD</p>
        </NavLink>
      </Stack>
    </Stack>
  );
}
