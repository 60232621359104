import { FormControl, InputGroup } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";

export default function SearchInputView(props) {
  return (
    <InputGroup className={"rounded border d-flex justify-content-center align-items-center ps-2"}>
      <BiSearch color={"grey"} size={18} />
      <FormControl
        defaultValue={props.defaultValue}
        type={"text"}
        className={"border-0 px-1 outline-0"}
        placeholder={props.placeholder ?? "Search Article"}
        aria-label="Search"
        aria-describedby="Search"
        onBlur={props.onBlur}
        onKeyPress={(e) => {
          if (e.key.toLowerCase() === "enter") {
            e.preventDefault();
            document.activeElement.blur();
          }
        }}
        onKeyUp={props.onKeyUp}
      />
    </InputGroup>
  );
}
