import { useContext, useEffect, useRef, useState } from "react";
import { Container, Nav, Navbar, NavDropdown, Button, Image, OverlayTrigger, Popover, Stack } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FirebaseAuthUI, FirebaseContext } from "./FirebaseContext";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import MenuView from "./MenuView";
import { useLocation } from "react-router-dom";

export default function NavigationBar(props) {
  let fire = useContext(FirebaseContext);

  const [show, setShow] = useState(false);
  const [showshadow, setshowshadow] = useState(false);

  const location = useLocation();

  const signOutClickAction = (e) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: <small className={"mt-5"}>Signing Out.</small>,
      text: "Would you like to Sign Out ?",
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: true,
      focusCancel: false,
    }).then((res) => {
      if (res.isConfirmed) {
        fire.signOutAction();
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollAction);
    return () => {
      window.removeEventListener("scroll", onScrollAction);
    };
  }, []);

  const onScrollAction = (e) => {
    if (window.pageYOffset > 200) {
      setshowshadow(true);
    } else {
      setshowshadow(false);
    }
  };

  const ui_get_logoname = (res) => {
    return (
      <div className={"d-inline-block text-secondary"} onClick={() => setShow(!show)}>
        <b>Fabrigram</b>
        <small className={"mx-1"}>by ALC</small>
        {res && <MdKeyboardArrowDown />}
      </div>
    );
  };

  return (
    <Navbar bg="white" expand="lg" fixed="top" className={showshadow && "shadow"}>
      <Container fluid>
        <Navbar.Brand className={"cursor-pointer"}>
          <Link to={"/"}>
            <img alt="" src="/images/FabrigramIcon.png" width="30" height="30" className="d-inline-block align-top me-2" />
          </Link>
          {location.pathname === "/" || location.pathname === "/index" || location.pathname === "/signup" ? (
            <> {ui_get_logoname()}</>
          ) : (
            <OverlayTrigger
              show={show}
              trigger="click"
              placement="bottom"
              rootClose={true}
              onToggle={(r) => !r && setShow(false)}
              style={{ width: "600px", height: "600px" }}
              overlay={
                <Popover id="popover-basic">
                  <Popover.Body>
                    <MenuView show={show} onLogoClickAction={() => setShow(false)} />
                  </Popover.Body>
                </Popover>
              }
            >
              {ui_get_logoname(true)}
            </OverlayTrigger>
          )}
        </Navbar.Brand>
        {fire.middleview}
        <div className={"ms-auto"}>
          {fire && fire.userDATA ? (
            <NavDropdown align={"end"} id="navbar-dropdown" title={<div className="pull-right d-inline-block">{fire.userDATA.photoURL ? <Image roundedCircle src={fire.userDATA.photoURL} alt="" width="30" height="30" /> : <FaUserCircle size={30} />}</div>}>
              <NavDropdown.Item key={"navbar-dropdown-displayname"} className={"w-100"}>
                <b>Hi {fire.userDATA.displayName ?? fire.userDATA.email}</b>
              </NavDropdown.Item>
              {fire.dropdownButtons}
              <NavDropdown.Item key={"navbar-dropdown-signout"} className={"w-100"} onClick={signOutClickAction}>
                SignOut
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Button variant={"outline-dark"} size="sm" onClick={() => fire.signInClickAction(fire)}>
              Sign In
            </Button>
          )}
        </div>
      </Container>
    </Navbar>
  );
}
