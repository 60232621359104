import { Spinner } from "react-bootstrap";

export default function LoadingView(props) {
  return (
    <div className={`${props.className} position-absolute top-0 bottom-0 start-0 d-flex w-100 h-100 z-index-900`}>
      <Spinner variant={props.variant ?? "dark"} animation={"border"} role={"status"} className={"m-auto"}>
        <span className={"visually-hidden"}>Loading...</span>
      </Spinner>
    </div>
  );
}
