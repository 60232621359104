import { downloadURL } from "./helpers";

const url1 = "https://alctexservice.com:88";
const url2 = "https://alctexserv.com:870";
const url3 = "https://alctexserv.com/api/Production";
export const imgdatabase = "https://alctexserv.com:891";

export function fetchaction(parameters, url, _options) {
  let options = _options ?? {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "max-age=60",
    },
    body: JSON.stringify(parameters),
  };

  return fetch(url ?? url1, options)
    .then((callback) => (callback.json && callback.json()) ?? callback)
    .catch((err) => err);
}

export function json_to_formbody(data) {
  var formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
}

export function fetch_sql_collectiondetail_DATA(d) {
  let json;
  if (typeof d === "string") {
    json = {
      ReqType: "SELECT_FABRICRAMFORSCAN",
      OrderType_id: "0",
      Store_id: "20",
      ColorFlag: "AND",
      YarnCountFlag: "AND",
      OrderBookDet_id: d,
    };
  } else {
    delete d.isFilterApplied;
    json = d;
  }
  const para = { SPName: "USP_tblTFabricRamBuyerCollections", JSONData: json };
  return fetchaction(para);
}

export function fetchOBDetailsTags(d) {
  const para = {
    SPName: "USP_FabrigramOBDetils",
    JSONData: { ReqType: "SELECT_DETAILSTAGS", OrderBookDet_id: d },
  };
  return fetchaction(para);
}

export function fetchOBDetailsStocks(d, IsPKJStock, IsInternalUser, query) {
  const para = {
    SPName: "USP_FabrigramOBDetils",
    JSONData: { ReqType: "SELECT_DETAILS_STOCK", OrderBookDet_id: d, IsPKJStock, IsInternalUser },
  };

  if (query && typeof query === "object" && query.IsFIJStock === "Y") {
    para.JSONData.IsFIJStock = "Y";
  }
  return fetchaction(para);
}

export function fetchFabLibraryFolderImagePath(ids) {
  const start = new Date().getTime();
  const para = {
    SPName: "USP_tblTFabricRamBuyerCollections",
    JSONData: { ReqType: "SELECT_FABRICIMAGE", OrderBookDet_id: ids },
    GroupBy: "OrderCode",
    GroupField: "ImagePath",
  };

  return fetchaction(para)
    .then((callback) => {
      console.log("fetchFabLibraryFolderImagePath +> Execution End => " + ((new Date().getTime() - start) / 1000).toString() + " seconds ");
      let imagePathJSON = {};
      let BSimagePathJSON = {};
      for (var key in callback) {
        let result = callback[key];
        for (let i = 0; i < result.length; i++) {
          let url = result[i];
          if (key) {
            let _key = key.replace("/", "_");
            const bs = url.includes("BS");
            const xs = url.includes("XS");
            if (bs || xs) {
              if (!BSimagePathJSON[_key]) BSimagePathJSON[_key] = [url];
              else {
                if (xs) BSimagePathJSON[_key].unshift(url);
                else BSimagePathJSON[_key].push(url);
              }
            } else {
              if (!imagePathJSON[_key]) imagePathJSON[_key] = [url];
              else imagePathJSON[_key].push(url);
            }
          }
        }
      }
      return [imagePathJSON, BSimagePathJSON];
    })
    .catch((err) => [null, null]);
}

export function fetch_collection_excel(d) {
  let options = {
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: json_to_formbody(d),
  };
  return fetchaction(null, url3 + "/FabrigramDownloadExcel", options).then((res) => {
    if (res && res.URL) {
      return downloadURL(res.URL.replace("~", imgdatabase), d.CollectionTitle);
    }
    return Promise.reject(false);
  });
}

export function fetchFabricLibrary(data) {
  const para = { SPName: "USP_tblTFabricRamBuyerCollections", JSONData: data };
  return fetchaction(para);
}

export function fetchFilterDetails(type, data) {
  const json = { ...{ SelectType: type }, ...data };
  const para = { SPName: "USP_FabricLibraryFilter", JSONData: json };
  return fetchaction(para);
}

export function fetchDesignDetails(Design_id) {
  const para = { Design_id: Design_id };
  return fetchaction(para, url1 + "/design/designdetail");
}

export function fetchOrdersSearch(LikeStr, Design_id) {
  let json = { ReqType: "SELECT_ORDER_FABWEB", LikeStr };
  if (Design_id) json["Design_id"] = Design_id;
  const para = {
    SPName: "USP_GetOrderCode",
    JSONData: json,
  };
  return fetchaction(para).then((res) => res.length && res[0]);
}
