import { Container } from "react-bootstrap";
import { FcAutomotive } from "react-icons/fc";

export default function EmptyView(props) {
  return (
    <Container className={"d-flex text-center justify-content-center align-items-center vh-100 "}>
      <div>
        <FcAutomotive size={100} />
        <div className={"lead"}>{props.title ?? `Something's wrong here.`}</div>
      </div>
    </Container>
  );
}
