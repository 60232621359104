import { useContext, useState, useMemo } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FcOk } from "react-icons/fc";
import { Link, useSearchParams } from "react-router-dom";
import { FirebaseAuthUI, FirebaseContext } from "../components/FirebaseContext";
import LoadingView from "../components/LoadingView";
import MenuView from "../components/MenuView";
import { fetch_signup } from "../utility/fire_fetcher";
import IndexPage from "./IndexPage";

export default function SignupPage(props) {
  let fire = useContext(FirebaseContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [validated, setValidated] = useState(false);
  const submitted = searchParams.get("submitted");
  let access = useMemo(() => fire.isdomainuser(), [fire]);

  if (fire.userDATA_isLoading) {
    return <LoadingView key={"loading"} />;
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      setSearchParams({ submitted: "true" });

      if (fire.userDATA) {
        const d = Object.fromEntries(new FormData(form).entries());
        let text = `From: ${fire.userDATA.displayName ?? `null`} \nBrand: ${d.brand} \nMail: ${fire.userDATA.email}\nDomain Access: ${fire.isdomainuser()}`;
        fetch_signup(fire.userDATA.email, text);
      }
    }
    setValidated(true);
    e.preventDefault();
  };

  return (
    <Container style={{ minHeight: "100vh" }} className={"m-auto d-flex align-items-center justify-content-center"}>
      <div className={"w-75"}>
        {fire.userDATA ? (
          <>
            {access ? (
              <>
                <h4 className={"display-4 fw-bold"}>{fire.get_welcomename()}</h4>
                <p className={"lead"}>👍 You are good to go. Team Fabrigram has granted full access to you already.</p>
                <Link to={"/index"}>
                  <Button className={"mt-5"} variant="primary">
                    Go to Menu
                  </Button>
                </Link>
              </>
            ) : (
              <>
                {submitted === "true" ? (
                  <>
                    <h4 className={"display-4 fw-bold text-success"}>
                      <FcOk size={80} /> Thank you !
                    </h4>
                    <p className={"lead"}>We'll reachout to you shortly. Fabrigram will be fully accessible once granted access for your brand/buying house. We are available at fabrigram@alctex.com for any queries.</p>
                    <p className={"lead"}>In the meatime, feel free to access few of our app features.</p>
                    <MenuView direction={isMobile ? "vertical" : "horizontal"} gap={isMobile ? 2 : 5} className={"pt-5"} />
                  </>
                ) : (
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group controlId="forminput_brand">
                      <h4 className={"display-4 fw-bold"}>{fire.get_welcomename()} </h4>
                      <p className={"lead"}>Prior to use, Fabrigram team would to grant access to the brand/user to login and view all of its contents. We would like to know your brand/buying house.</p>
                      <Form.Control required className={"lead"} name={"brand"} type="text" placeholder="Enter Brand Name" />
                      <Form.Control.Feedback type="invalid">Error</Form.Control.Feedback>
                    </Form.Group>
                    <Button className={"mt-5"} variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                )}
              </>
            )}
          </>
        ) : (
          <div className={"text-center"}>
            <img className={"m-3"} src={"images/FabrigramIcon.png"} alt={"Fabrigram"} width={80} height={80} />

            <p className={"lead"}>
              Thanks for your interest in Fabrigram. Fabrigram is free to use for all Apparel Brands. Prior to use, Fabrigram Team would need to grant access to the brand/user to login and view all of its contents. Upon Sign up, Fabrigram Team will reach you out via Email shortly.
            </p>
            <FirebaseAuthUI fire={fire} onComplete={(res) => setSearchParams({ submitted: "true" })} className={"pt-5"} />
          </div>
        )}
      </div>
    </Container>
  );
}
