import { useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Dropdown, OverlayTrigger, Popover, Row, Stack, Tooltip } from "react-bootstrap";
import { CgMoreR } from "react-icons/cg";

export function ColorCard(props) {
  const [selected, setselected] = useState(props.selected);

  const onClick = () => {
    props.onSelected && props.onSelected(!selected, props.d);
    props.type === 1 && setselected(!selected);
  };
  return (
    <div id={props.id} className={`${props.className ?? `col`}`}>
      <div onClick={onClick} class={`${!props.modal && `shadow-sm cursor-pointer border`}  bg-light rounded transform-transition ${selected && props.type === 1 && `transform-scale-0_6`}`} style={{ width: "100px" }}>
        <div className="position-relative" style={{ height: "70px", backgroundColor: `#${props.d.hex}` }}>
          {props.dropdownItems && (
            <Dropdown as={ButtonGroup} size="sm" align={"end"} drop={"up"}>
              <Dropdown.Toggle variant="link" className={"m-0 p-0 dropdown-toggle-noarrow"} drop={"up"}>
                <CgMoreR className="mx-1 p-0 icon cursor-pointer btn-push" size={15} color={"white"} onClick={() => props.moreAction && props.moreAction()} />
              </Dropdown.Toggle>
              <Dropdown.Menu drop={"up"}>{props.dropdownItems}</Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <small className="lh-1 p-1 d-block text-nowrap" style={{ letterSpacing: -0.1, height: "50px" }}>
          <div className="fw-bold">
            {props.d.type ? "ARCHROMA" : "PANTONE"}
            <small>
              <sup> ®</sup>
            </small>
          </div>
          <div>{props.d.code}</div>
          {props.d.name && <div>{props.d.name}</div>}
        </small>
      </div>
    </div>
  );
}
