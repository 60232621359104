import { useContext, useMemo } from "react";
import { Alert, Container, Spinner } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FirebaseAuthUI, FirebaseContext } from "../components/FirebaseContext";
import LimitedAccessView from "../components/LimitedAccessView";
import LoadingView from "../components/LoadingView";
import MenuView from "../components/MenuView";

export default function IndexPage(props) {
  const fire = useContext(FirebaseContext);
  let access = useMemo(() => fire.isdomainuser(), [fire]);

  return (
    <Container style={{ minHeight: "100vh" }} className={"m-auto d-flex align-items-center justify-content-center"}>
      <div className={"text-center"}>
        {fire.userDATA_isLoading || (fire.userDATA && typeof access !== "boolean") ? (
          <LoadingView />
        ) : (
          <>
            <img className={"m-3"} src={"images/FabrigramIcon.png"} alt={"Fabrigram"} width={80} height={80} />
            {fire.userDATA ? (
              <>
                <h4>{fire.get_welcomename()} </h4>
                {access === false && <LimitedAccessView />}
                <MenuView direction={isMobile ? "vertical" : "horizontal"} gap={isMobile ? 2 : 5} className={"pt-5"} />
              </>
            ) : (
              <>
                <h4>Sign In to Fabrigram</h4>
                {props.subtitle}
                <FirebaseAuthUI fire={fire} onComplete={(res) => null} className={"pt-5"} />
              </>
            )}
          </>
        )}
      </div>
    </Container>
  );
}
