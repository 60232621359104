import { useContext, useEffect, useMemo, useState } from "react";
import { Container, Button, Row, Col, Image, NavDropdown } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { queryClient } from "../App";
import EmptyView from "../components/EmptyView";
import { FirebaseContext } from "../components/FirebaseContext";
import LimitedAccessView from "../components/LimitedAccessView";
import { SharedWithContainer } from "../components/SharedWithView";
import SkeletonTitleView from "../components/SkeletonTitleView";
import SwatchCardContainer from "../components/SwatchCardContainer";
import TitleView from "../components/TitleView";
import { fetch_collection_excel, fetch_sql_collectiondetail_DATA } from "../utility/fetcher";
import { fetch_fire_collection_DATA, fetch_fire_collectiondetail_DATA, downloadOBDetailImage, fire_downloadImage, fetch_ImageWithObDetails, fetch_ObGridPDF, fetch_collectionsGroupCombos, fetch_addShareWith, fetch_AllStories } from "../utility/fire_fetcher";
import { getIDs2, swalToastProps, getFileNameString, mutationDefaults, getCollectionLink, shareCollectionLinkAction, scrollToTop } from "../utility/helpers";
import { StoryPage } from "./StoryPage";

export function CollectionPage(props) {
  const fire = useContext(FirebaseContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let groupID = searchParams.get("Id");
  let [OrderBookDet_ids, set_OrderBookDet_ids] = useState();

  const fire_collection_DATA = useQuery(`fire_collection_DATA_${groupID}`, () => (groupID ? fetch_fire_collection_DATA(groupID) : null));

  const fire_collectiondetail_DATA = useQuery(`fire_collectiondetail_DATA_${groupID}`, () => (groupID ? fetch_fire_collectiondetail_DATA(groupID) : null));

  const fire_collectioncombos_DATA = useQuery(`fire_collectioncombos_DATA_${groupID}`, () => (groupID ? fetch_collectionsGroupCombos(groupID) : null));

  const downloadAllOB_Mutation = useMutation((d) => fetch_ImageWithObDetails(d), mutationDefaults);
  const downloadPPT_Mutation = useMutation((d) => fetch_ObGridPDF(d), mutationDefaults);
  const downloadExcel_Mutation = useMutation((d) => fetch_collection_excel(d), mutationDefaults);

  // const fire_more_story_DATA = useQuery(`fire_more_story_DATA_${groupID}`, () => fetch_AllStories({ count: 2, groupID, isStoryEnabled: true }), { enabled: typeof groupID === "string" && fire_collectiondetail_DATA.isSuccess });

  useEffect(() => {
    return () => {
      document.title = "Fabrigram by ALC";
      fire.setdropdownButtons([]);
    };
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [groupID]);

  useEffect(() => {
    if (fire_collectiondetail_DATA.isSuccess) {
      let ids = getIDs2(fire_collectiondetail_DATA.data, "OrderBookDet_id").join(",");
      set_OrderBookDet_ids(ids);
    }
  }, [fire_collectiondetail_DATA]);

  //#region download Methods
  let downloadAllOBAction = (zip, grid) => {
    let json = {
      orderbookdet_ids: OrderBookDet_ids.split(","),
      group_id: fire_collection_DATA.data._id,
      IsInternalUser: fire.isinternaluser() ? "Y" : "N",
      ReturnContentType: zip ? "application/zip" : "application/pdf",
      filename: fire_collection_DATA.data.Remarks,
      IsPKJStock: fire_collection_DATA.data.isStockStory ? "Y" : "N",
    };
    const filename = getFileNameString(fire_collection_DATA.data.Remarks);
    if (grid) {
      json.grid = grid;
      downloadPPT_Mutation.mutate({ json, filename });
    } else {
      downloadAllOB_Mutation.mutate({ json, filename, zip });
    }
  };

  const downloadExcelAction = () => {
    let temp = fire_collectiondetail_DATA.data.map((d) => {
      return {
        CutWidth: d.CutWidth ?? "",
        Price: d.Price ?? "",
        ImagePath: d.ImagePath ?? "",
        OrderBookDet_id: d.OrderBookDet_id,
        R: d.r ? String(d.r) : "",
        S: d.s ? String(d.s) : "",
      };
    });

    let json = {
      CollectionID: fire_collection_DATA.data._id,
      CollectionTitle: getFileNameString(fire_collection_DATA.data.Remarks),
      IsForExcel: "Y",
      IsInternalUser: fire.isinternaluser() ? "Y" : "N",
      IsPKJStock: fire_collection_DATA.data.isStockStory ? "Y" : "N",
      JSon: JSON.stringify(temp),
    };
    downloadExcel_Mutation.mutate(json);
  };

  useEffect(() => {
    if (fire_collection_DATA.isSuccess && fire.userDATA && groupID) {
      let data = fire_collection_DATA.data;
      document.title = `Fabrigram by ALC - ${data.Remarks}`;
      if (data.isStory) return;
      let r = Array.isArray(data.sharedWith) && data.sharedWith.find((d) => d === fire.userDATA.uid);
      if (!r) {
        fetch_addShareWith(groupID, fire.userDATA.uid, data).then((res) => {
          if (res) {
            queryClient.setQueryData(`fire_collection_DATA_${groupID}`, (data) => {
              if (data.sharedWith) data.sharedWith.push(fire.userDATA.uid);
              return data;
            });
            let d = { ...{ title: `Hi ${fire.userDATA.displayName ?? fire.userDATA.email}`, text: "This Collection has been added to you Shared List" }, ...swalToastProps };
            Swal.fire(d);
          }
        });
      }
    }
  }, [fire_collection_DATA.isSuccess, fire.userDATA, groupID]);

  useEffect(() => {
    if (fire_collectiondetail_DATA.isSuccess && fire_collection_DATA.isSuccess && !fire_collection_DATA.data.isStockStory) {
      let b1 = (
        <NavDropdown.Item key={`navbar-dropdown-b1`} onClick={() => downloadAllOBAction(false)}>
          Download all Articles - PDF
        </NavDropdown.Item>
      );
      let b2 = (
        <NavDropdown.Item key={`navbar-dropdown-b2`} onClick={() => downloadAllOBAction(true)}>
          Download all Articles - ZIP
        </NavDropdown.Item>
      );
      let b3 = (
        <NavDropdown.Item key={`navbar-dropdown-b3`} onClick={() => downloadAllOBAction(null, 3)}>
          Download Presentation - PDF (3 Per Page)
        </NavDropdown.Item>
      );
      let b4 = (
        <NavDropdown.Item key={`navbar-dropdown-b4`} onClick={() => downloadAllOBAction(null, 6)}>
          Download Presentation - PDF (6 Per Page)
        </NavDropdown.Item>
      );
      let b5 = (
        <NavDropdown.Item key={`navbar-dropdown-b5`} onClick={() => downloadExcelAction()}>
          Download Excel
        </NavDropdown.Item>
      );
      fire.setdropdownButtons([b1, b2, b3, b4, b5]);
    }
  }, [fire_collection_DATA.isSuccess, fire_collectiondetail_DATA.isSuccess, OrderBookDet_ids, fire.userDATA, fire_collection_DATA.data]);

  if (!groupID) {
    return <EmptyView />;
  } else if (fire_collection_DATA.isLoading || fire_collectiondetail_DATA.isLoading) {
    return (
      <Container className={"mt-5 pt-5"}>
        <SkeletonTitleView subtitle />
        <SharedWithContainer isLoading />
        <SwatchCardContainer isLoading />
      </Container>
    );
  } else if (!fire_collection_DATA.isSuccess || !fire_collectiondetail_DATA.isSuccess) {
    return <EmptyView />;
  }

  return (
    <>
      {fire_collection_DATA.data && fire_collection_DATA.data.isStory && <StorySplashView data={fire_collection_DATA.data} />}
      <Container>
        {!fire_collection_DATA.data.isStory && (
          <>
            <div className={"mt-5 pt-5"}>
              <TitleView title={fire_collection_DATA.data.Remarks} subtitle={`${fire_collectiondetail_DATA.data.length} articles`} />
              <Button variant="outline-primary" size="sm" className={"mt-4"} onClick={() => shareCollectionLinkAction(fire, fire_collection_DATA.data, null)}>
                Share Collection
              </Button>
              <p className={"lead text-secondary mt-3"}>
                Share this collection link with your Team or Mill for them to have a look at the articles in this collection you have ❤️ liked. Mill can arrange physical headers for the liked articles accordingly. Mill can also develop desklooms in the New Colorways created using Fabrigram.
              </p>
            </div>
            <SharedWithContainer data={fire_collection_DATA.data.sharedWith} />
          </>
        )}
        <SwatchCardContainer gDATA={fire_collectiondetail_DATA.data} OrderBookDet_ids={OrderBookDet_ids} pDATA={fire_collection_DATA.data} cDATA={fire_collectioncombos_DATA.data} />
        {fire_collectiondetail_DATA.isSuccess && <StoryPage query={{ count: 3, groupID, isStoryEnabled: true }} />}
      </Container>
    </>
  );
}

function StorySplashView(props) {
  let imagepath = useQuery(`StoryCard_ImagePath${props.data && props.data.ImagePath}`, () => fire_downloadImage(null, props.data.ImagePath), { enabled: typeof props.data === "object" });

  return (
    <Container fluid className={"position-relative vw-100 vh-100 m-0 p-0"}>
      <Image fluid className={`m-0 p-0 vw-100 vh-100 object-fit-cover opacity-transition ${imagepath.data && `opacity-100`}`} src={imagepath.data} alt={"Loading"} />
      <div className={"position-absolute top-50 start-50 translate-middle text-light text-center"}>
        <h1 className={"display-3 text-uppercase fw-bold"}>{props.data.Remarks}</h1>
        {props.data.description && <h3>{props.data.description}</h3>}
      </div>
    </Container>
  );
}
