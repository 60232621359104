import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function LimitedAccessView(props) {
  return (
    <Alert variant={"danger"} className={"mt-4 text-center"}>
      Currently, you have limited access in Fabrigram.
      <br />
      To get Full Access, Please reachout to ALC Team.
      <br />
      <Alert.Link>
        <Link to={"/signup"} style={{ color: "inherit" }}>
          Get Full Access
        </Link>
      </Alert.Link>
    </Alert>
  );
}
