import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import "react-loading-skeleton/dist/skeleton.css";
import NavigationBar from "./components/NavigationBar";
import { FirebaseProvider } from "./components/FirebaseContext";
import { Routes, Route } from "react-router-dom";
import { CollectionPage } from "./pages/CollectionPage";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThreeDView } from "./components/ThreeDView";
import { StoryPage } from "./pages/StoryPage";
import { HomePageProvider } from "./pages/HomePage";
import { useEffect } from "react";
import AboutView from "./components/AboutView";
import TermsPage from "./pages/TermsPage";
import PolicyPage from "./pages/PolicyPage";
import WelcomePage from "./pages/WelcomePage";
import IndexPage from "./pages/IndexPage";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "./utility/helpers";
import SignupPage from "./pages/SignupPage";
import CadPage from "./pages/CadPage";
import EmptyView from "./components/EmptyView";
import ImageUploadPage from "./pages/ImageUploadPage";
import { CostingPage } from "./pages/CostingPage";
import { ColorBook } from "./pages/ColorBook";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: true,
      refetchOnReconnect: true,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
    },
  },
});

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <FirebaseProvider>
          <NavigationBar />
          <Routes>
            <Route path="/collection" element={<CollectionPage />} />
            <Route path="/threedobj" element={<ThreeDView />} />
            <Route path="/home" element={<HomePageProvider />} />
            <Route path="/stories" element={<StoryPage />} />
            <Route path="/stocks" element={<StoryPage stocks={true} />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/policy" element={<PolicyPage />} />
            <Route path="/index" element={<IndexPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/cad" element={<CadPage />} />
            <Route path="/costing" element={<CostingPage />} />
            <Route path="/colorbook" element={<ColorBook />} />
            {/* <Route path="/admin/imageupload" element={<ImageUploadPage />} /> */}
            <Route path="/" element={<WelcomePage />} />
            <Route path="*" element={<EmptyView title={"Error 404 - Page Not Found"} />} />
          </Routes>
          {pathname && !pathname.includes("/cad") && <AboutView />}
        </FirebaseProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
