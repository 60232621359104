import { createContext, useContext, useEffect, useMemo, useState } from "react";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "@firebase/functions";

import { getAuth, onAuthStateChanged, signInWithPopup, signOut, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { Button, Image } from "react-bootstrap";
import { useQuery } from "react-query";
import { fetch_domainaccess, fetch_internaluser } from "../utility/fire_fetcher";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { swalToastProps } from "../utility/helpers";
import { isMobile } from "react-device-detect";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyClaN0XDZiMUGX6Wu1Y5ynxVh2GqjBx86M",
  authDomain: "alcfabriclibraryfirebase.firebaseapp.com",
  databaseURL: "https://alcfabriclibraryfirebase.firebaseio.com",
  projectId: "alcfabriclibraryfirebase",
  storageBucket: "alcfabriclibraryfirebase.appspot.com",
  messagingSenderId: "998095023501",
  appId: "1:998095023501:web:d803797eced3b85a74e1c4",
  measurementId: "G-TNP0DNJM58",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth();
export const functions = getFunctions();
// connectFunctionsEmulator(functions, "localhost", 5001);

export let default_storageRef = getStorage(app);
export let alccloud_storageRef = getStorage(app, "gs://alccloud");

export const FirebaseContext = createContext(null);

export function FirebaseProvider(props) {
  const [userDATA_isLoading, setuserDATA_isLoading] = useState(true);
  const [userDATA, setuserDATA] = useState();
  const [dropdownButtons, setdropdownButtons] = useState();
  const [middleview, setmiddleview] = useState();
  const internaluserDATA = useQuery(`internaluserDATA${userDATA && userDATA.uid}`, () => fetch_internaluser(userDATA.uid), { enabled: typeof userDATA === "object" });
  const domainaccessDATA = useQuery(`domainaccessDATA${userDATA && userDATA.email}`, () => fetch_domainaccess(userDATA.email), { enabled: typeof userDATA === "object" }, { onSettled: () => setuserDATA_isLoading(false) });

  const isinternaluser = () => {
    if (internaluserDATA.isSuccess) {
      let d = internaluserDATA.data.internal_userid;
      if (d) return true;
      return false;
    }
    return null;
  };

  const isdomainuser = () => {
    if (domainaccessDATA.isLoading || domainaccessDATA.isIdle) return null;
    if (domainaccessDATA.data) return domainaccessDATA.data;
    return false;
  };

  const get_welcomename = () => {
    return `Hi ${userDATA && typeof userDATA.displayName === "string" ? userDATA.displayName : "There"}`;
  };

  const signInClickAction = (fire, title, text) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: <small className={"mt-5"}>{title ?? `Welcome Back.`}</small>,
      footer: <small className={"text-center text-secondary"}>Click “Sign In” to agree to Fabrigram's Terms of Service and acknowledge that Fabrigram's Privacy Policy applies to you.</small>,
      html: (
        <div>
          {text && <small className={"text-secondary"}>{text}</small>}
          <FirebaseAuthUI fire={fire} onComplete={(res) => MySwal.close()} />
        </div>
      ),
      showConfirmButton: false,
      showCloseButton: true,
    }).then((res) => {
      if (res.isDismissed) {
        // setuserDATA_isLoading(false);
      }
    });
  };

  const signOutAction = () => {
    setuserDATA_isLoading(true);
    signOut(auth)
      .then(() => {
        setuserDATA(null);
      })
      .catch((error) => {
        alert(error);
      })
      .then(() => {
        setuserDATA_isLoading(false);
      });
  };

  useEffect(() => {
    setuserDATA_isLoading(true);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setuserDATA(user);
      } else {
        setuserDATA(false);
      }
      setuserDATA_isLoading(false);
    });
  }, []);

  return <FirebaseContext.Provider value={{ userDATA, setuserDATA, signOutAction, signInClickAction, isinternaluser, isdomainuser, dropdownButtons, middleview, setmiddleview, setdropdownButtons, get_welcomename, userDATA_isLoading, domainaccessDATA }}>{props.children}</FirebaseContext.Provider>;
}

export function FirebaseAuthUI(props) {
  const signInAction = (provider, type) => {
    let authprovider;
    if (provider === GoogleAuthProvider) {
      authprovider = new GoogleAuthProvider();
      authprovider.setCustomParameters({
        prompt: "select_account",
      });
    } else if (provider === OAuthProvider && type === "microsoft") {
      authprovider = new OAuthProvider("microsoft.com");
      authprovider.setCustomParameters({
        prompt: "select_account",
      });
      authprovider.addScope("User.Read.All");
    } else if (provider === OAuthProvider && type === "yahoo") {
      authprovider = new OAuthProvider("yahoo.com");
      authprovider.addScope("profile");
      authprovider.addScope("email");
    }
    signInWithPopup(auth, authprovider)
      .then((result) => {
        const user = result.user;
        props.fire.setuserDATA(user);
        props.onComplete && props.onComplete(true);
      })
      .catch((error) => {
        let d = { ...swalToastProps, ...{ title: "Error : Unable to Sign In", text: error.code, icon: "error" } };
        Swal.fire(d);
        props.fire.setuserDATA(null);
      });
  };

  const signInButton = (title, image, onClick) => {
    return (
      <Button className={"text-start"} variant="outline-dark" onClick={onClick}>
        <Image className={"me-3 float-start"} width={28} height={28} src={image} thumbnail />
        {title}
      </Button>
    );
  };

  return (
    <div className={`d-flex flex-column gap-3 py-4 m-auto ${props.className}`} style={{ width: isMobile ? "100%" : "280px" }}>
      {signInButton("Sign in with Google", "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg", () => signInAction(GoogleAuthProvider))}
      {signInButton("Sign in with Microsoft", "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg", () => signInAction(OAuthProvider, "microsoft"))}
      {signInButton("Sign in with Yahoo", "https://upload.wikimedia.org/wikipedia/commons/8/83/Yahoo%21_icon_%282009-2013%29.svg", () => signInAction(OAuthProvider, "yahoo"))}
    </div>
  );
}
