import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { FirebaseContext } from "../components/FirebaseContext";
import LazyImage from "../components/LazyImage";
import LimitedAccessView from "../components/LimitedAccessView";
import SkeletonTitleView from "../components/SkeletonTitleView";
import TitleView from "../components/TitleView";
import { fetch_AllStories, fire_downloadImage } from "../utility/fire_fetcher";
import { getCollectionLink, shareCollectionLinkAction, timestampToDate } from "../utility/helpers";

export function StoryPage(props) {
  const fire_story_DATA = useQuery(`fire_story_DATA_${props.query ? props.query.groupID : props.stocks ? "stocks" : "stories"}`, () => fetch_AllStories(props.query ?? (props.stocks ? { isStockStory: true } : {})));
  let fire = useContext(FirebaseContext);
  let access = useMemo(() => fire.isdomainuser(), [fire]);

  if (fire_story_DATA.isLoading) {
    return (
      <Container className={"mt-5 pt-5"}>
        <SkeletonTitleView />
        <Row xs={1} md={2} lg={3} className="g-3 py-5">
          <StoryCard isLoading />
          <StoryCard isLoading />
          <StoryCard isLoading />
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <TitleView
        className={"mt-5 pt-5"}
        title={props.stocks ? "Stocks" : "Stories"}
        subtitle={
          props.stocks
            ? `Checkout ALC's Stock Fabrics Collections Below. Each of the Digital Stock Collection gets automatically updated on daily with the latest stock quantity , fabric details , images and more.
Email balepacking@alctex.com if you would like to get more details regarding our stock fabrics.`
            : `Stories are weekly blog articles from ALC with Innovative Product Developments , Seasonal Collections , New Fashion Trends & much more.`
        }
      />
      {props.query && (
        <Link to={"/stories"} className={""}>
          <p className={"lead"}>
            View All Stories <FiArrowRight />
          </p>
        </Link>
      )}
      <Row xs={1} md={2} lg={3} className="g-3 py-5">
        {fire_story_DATA.data.map((d, i) => (
          <StoryCard key={i} data={d} />
        ))}
      </Row>
      {fire.userDATA && access === false && <LimitedAccessView />}
    </Container>
  );
}

function StoryCard(props) {
  const fire = useContext(FirebaseContext);
  let imagepath = useQuery(`StoryCard_ImagePath${props.data && props.data.ImagePath}`, () => fire_downloadImage(null, props.data.ImagePath), { enabled: typeof props.data === "object" });

  return (
    <Col>
      <Card className={"shadow h-100"}>
        <div style={{ height: "450px" }}>{imagepath.data && <LazyImage src={imagepath.data} height={"450px"} />}</div>
        <Card.Body className={"d-flex flex-column"}>
          <Card.Title>{props.isLoading ? <Skeleton /> : props.data.Remarks}</Card.Title>
          {props.isLoading ? <Skeleton /> : props.data.description && <Card.Text>{props.data.description}</Card.Text>}
          <div className={"mt-auto"}>
            {props.data && (
              <>
                <Link to={`/collection?Id=${props.data._id}`}>
                  <Button variant="outline-primary">View Story</Button>
                </Link>
                <Button variant="outline-secondary" className={"ms-2"} onClick={() => shareCollectionLinkAction(fire, props.data, null)}>
                  Share Story
                </Button>
              </>
            )}
          </div>
          {/* <small className={"mt-3 text-secondary"}>{moment(timestampToDate(props.data.ModifiedOn)).format("LL")}</small> */}
        </Card.Body>
      </Card>
    </Col>
  );
}
