import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

export function PaginationView(props) {
  let [pageNumber, setpageNumber] = useState((props.pageNumber && parseInt(props.pageNumber)) ?? 1);

  let [ui_pages, setui_pages] = useState();

  const pageClickAction = (i) => {
    if (i !== pageNumber) {
      setpageNumber(i);
      props.onPageChange && props.onPageChange(i);
    }
  };

  useEffect(() => {
    let pages = Math.ceil(props.totalCount / props.pageSize);
    var arr = [];
    let s = Math.max(1, pageNumber - Math.ceil(props.visiblePages / 2));
    let f = Math.min(pages, s + props.visiblePages);

    arr.push(<Pagination.Prev disabled={pageNumber === s} onClick={() => pageClickAction(Math.max(1, pageNumber - 1))} />);

    for (var i = s; i <= f; i++) {
      const j = i;
      arr.push(
        <Pagination.Item key={j} active={i === pageNumber} onClick={() => pageClickAction(j)}>
          {i}
        </Pagination.Item>
      );
    }
    let e = Math.min(pages, pageNumber + 1);
    arr.push(<Pagination.Next disabled={pageNumber === pages} onClick={() => pageClickAction(e)} />);
    setui_pages(arr);
  }, [pageNumber, props.pageSize, props.totalCount, props.visiblePages]);

  if (!props.totalCount) {
    return null;
  }
  return <Pagination className={"m-2 my-5 justify-content-center"}>{ui_pages}</Pagination>;
}
