// import { Html, OrbitControls, PerspectiveCamera, useProgress } from "@react-three/drei";
import { Canvas, useLoader } from "@react-three/fiber";
import { downloadURL } from "../utility/helpers";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { Suspense, useEffect, useMemo, useState } from "react";
import { RepeatWrapping } from "three";
import { useLocation } from "react-router-dom";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { RiDownloadCloud2Fill } from "react-icons/ri";
import html2canvas from "html2canvas";
import { useQuery } from "react-query";
import { downloadOBDetailImage } from "../utility/fire_fetcher";
import { Html } from "@react-three/drei";

export function ThreeDView(props) {
  const location = useLocation();

  const imagepath = useMemo(() => {
    if (location && location.state && location.state.images3darr && location.state.images3darr.length > 0) {
      return location.state.images3darr[0];
    } else if (props && props.images3darr && props.images3darr.length > 0) {
      return props.images3darr[0];
    } else return null;
  }, [location, props]);

  const imagepath_fetch = useQuery(
    `ThreeDView_imagepath_${props.id}`,
    () => {
      return downloadOBDetailImage(imagepath).then((res) => {
        if (res.includes("alctex")) return null; //temp Code
        return res;
      });
    },
    { enabled: typeof imagepath === "string" }
  );

  const Scene = () => {
    const materials = useLoader(MTLLoader, "threedmodels/shirt/Men Shirt with Jeans and Sneakers.mtl");

    let texture = useLoader(TextureLoader, (imagepath_fetch.isSuccess && imagepath_fetch.data) ?? null);

    const obj = useLoader(OBJLoader, "threedmodels/shirt/Men Shirt with Jeans and Sneakers.obj", (loader) => {
      materials.preload();
      loader.setMaterials(materials);
    });

    useEffect(() => {
      if (obj && texture) {
        let shirt = obj.children.find((mesh) => mesh.material.name === "Shirt");
        shirt.material.map = texture;
        shirt.material.emissive = { r: 1, g: 1, b: 1 };
        shirt.material.roughness = 0.9;
        shirt.material.emissiveIntensity = -0.01;

        let norm = 1;
        if (imagepath && imagepath.includes("BS")) {
          norm = 2;
        }

        shirt.material.map.wrapS = shirt.material.map.wrapT = RepeatWrapping;
        shirt.material.map.repeat.set((texture.image.height / texture.image.width) * norm, norm);
      }
    }, [obj, texture]);

    return <>{imagepath && <primitive object={obj} position={[0, -100, 0]} />}</>;
  };

  const downloadAction = () => {
    html2canvas(document.body, { scrollX: -window.scrollX, scrollY: 0, windowWidth: document.documentElement.offsetWidth, windowHeight: document.documentElement.offsetHeight }).then(function (canvas) {
      const image = canvas.toDataURL("image/png");
      downloadURL(image, "file1");
    });
  };

  // return (
  //   <div className={`h-100 position-relative ${props.className}`}>
  //     <Canvas gl={{ preserveDrawingBuffer: true }}>
  //       <Suspense fallback={<Loader variant={props.variant} />}>
  //         <PerspectiveCamera makeDefault position={[0, 125, 56]} />
  //         <OrbitControls autoRotate={props.autoRotate ?? false} enableDamping dampingFactor={0.5} rotateSpeed={0.5} minDistance={10} maxDistance={200} minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} />
  //         <ambientLight intensity={1} />
  //         {/* <directionalLight intensity={0.5} /> */}
  //         {/* <pointLight intensity={10} distance={100} /> */}
  //         {imagepath_fetch.isSuccess && imagepath_fetch.data && <Scene />}
  //       </Suspense>
  //     </Canvas>
  //     {location && location.state && location.state.dict && (
  //       <div className={"position-absolute bottom-0 m-4"} style={{ maxWidth: 400 }}>
  //         <OverlayTrigger data-html2canvas-ignore placement="top" overlay={<Tooltip data-html2canvas-ignore>Download 3D Garment Rendering as an Image</Tooltip>}>
  //           <Button data-html2canvas-ignore className={"mb-4"} onClick={downloadAction}>
  //             Download
  //             <RiDownloadCloud2Fill className={"ms-2"} />
  //           </Button>
  //         </OverlayTrigger>
  //         <div className={"border rounded p-2"}>
  //           <h4>{location.state.dict.OrderCode}</h4>
  //           {location.subtitle}
  //         </div>
  //       </div>
  //     )}
  //   </div>
  // );
}

function Loader(props) {
  return;
  // const { loaded, errors } = useProgress();
  // return (
  //   <Html center className={"fw-bold text-center"}>
  //     {loaded ? <Spinner variant={props.variant ?? "dark"} animation={"border"} role={"status"} size="sm"></Spinner> : errors && <small>Error</small>}
  //   </Html>
  // );
}
